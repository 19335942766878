import { combineReducers } from "redux";

import TaskReducer from "./taskReducer";
import userReducer from "./userReducer";
import versionsReducer from "./verisonsReducer";

import folderReducer from "./folderReducer";
import PackageWithRobotsReducer from "./PackageWithRobotsReducer";
import updatesReducer from "./updatesReducer";
import robotprogramsReducer from "./robotprogramsReducer";
import programpackagesReducer from "./programpackagesReducer";
import ArosProgramsReducer from "./ArosProgramsReducer";
// import panelSetReducer from "./panelSetReducer";
import FileDescriptionReducer from "./FileDescriptionReducer";
import AllRobotsReducer from "./AllRobotsReducer";
import RobotPackagesReducer from "./RobotPackagesReducer ";
import AllRobotsTableReducer from "./AllRobotsTableReducer";
import AllFilesTableReducer from "./AllFilesTableReducer";
import GlobalReducer from "./GlobalReducer";
import AllUserReducer from "./AllUserReducer";
import RobotTypesReducer from "./RobotTypesReducer";
import AllDowloandFilesReducer from "./AllDownloadFilesReducer";
import ArosVersionReducer from "./ArosVersionReducer";
import UserRoleReducer from "./UserRoleReducer";

import AllProduceRobotsTableReducer from "./AllProduceRobotsTableReducer";
import GetAllUserSelectReducer from "./GetAllUserSelectReducer";
import AllTesterCommentReducer from "./AllTesterCommentReducer";
import AllRobotModelTable from "./AllRobotModelTable";
import ArosTesterReducer from "./ArosTesterReducer";
import AllPackagesTableReducer from "./AllPackagesTableReducer";

import DowloandProcessReducer from "./DowloandProcessReducer";
import AllStoreProgramsReducer from "./AllStoreProgramsReducer";
import SpinnerReducer from "./SpinnerReducer";
import ModalReducer from "./ModalReducer";
import SidebarReducer from "./SidebarReducer";
import AllTableStoreReducer from "./AllTableStoreReducer";
import AllStoreShowCaseReducer from "./AllStoreShowCaseReducer";
import PackagesCodeReducer from "./PackagesCodeReducer";
import AllMarkettingReducer from "./AllMarkettingReducer";
import RobotAllArosReducer from "./RobotAllArosReducer";
import RobotRunTimeInfoReducer from "./RobotRunTimeInfoReducer";

const reducers = (history) =>
  combineReducers({
    User: userReducer,
    Versions: versionsReducer,
    Folder: folderReducer,
    PackageWRobots: PackageWithRobotsReducer,
    Updates: updatesReducer,
    Programs: robotprogramsReducer,
    ProgramWPackages: programpackagesReducer,
    ArosPrograms: ArosProgramsReducer,
    FileDescription: FileDescriptionReducer,
    AllRobots: AllRobotsReducer,
    // Panel: panelSetReducer,
    RobotPackages: RobotPackagesReducer,
    AllRobotsTable: AllRobotsTableReducer,
    AllFilesTable: AllFilesTableReducer,
    Global: GlobalReducer,
    Users: AllUserReducer,
    RobotType: RobotTypesReducer,
    ArosVersion: ArosVersionReducer,
    UserRole: UserRoleReducer,
    ProduceRobot: AllProduceRobotsTableReducer,
    UserSelect: GetAllUserSelectReducer,
    AllTester: AllTesterCommentReducer,
    RobotModelTable: AllRobotModelTable,
    ArosTester: ArosTesterReducer,
    AllDownloadFiles: AllDowloandFilesReducer,
    SSpinner: SpinnerReducer,
    AllPackagesTable: AllPackagesTableReducer,
    Dowloand: DowloandProcessReducer,
    ModalAros: ModalReducer,
    SidebarToogle: SidebarReducer,
    StorePrograms: AllStoreProgramsReducer,
    AllStoreTablePrograms: AllTableStoreReducer,
    AllShowStorePrograms: AllStoreShowCaseReducer,
    PackagesCode: PackagesCodeReducer,
    AllMarketting: AllMarkettingReducer,
    RobotAros: RobotAllArosReducer,
    RunTime: RobotRunTimeInfoReducer,
  });
export default reducers;

import {
  faBars,
  faCodeBranch,
  faCogs,
  faHome,
  faPlus,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "react-awesome-slider/dist/styles.css";
// import background from "../assets/images/arkaPlan.jpg";
import { FaSignInAlt } from "react-icons/fa";
import { MdEngineering } from "react-icons/md";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogOut } from "../../../redux/actions/LoginAction";
import {
  GetAllFiles,
  GetAllPackages,
  GetAllRobotsTester,
  GetRobotPrograms,
  SidebarState,
} from "../../../redux/actions/taskActions";
export default function TesterSideBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [SideBar, SetSideBar] = useState(false);
  const [Profile, SetProfile] = useState("visible");
  const SidebarToogleUser = useSelector((state) => state.SidebarToogle);
  const User = useSelector((state) => state.User);
  //SİdeBaro Kontrol eder
  useEffect(() => {
    if (SideBar === true) {
      SetProfile("hidden");
    } else {
      SetProfile("visible");
    }
  }, [SideBar]);
  //Robota bağlı Özel Yazılımları çeker ve sayfa yönlendirmesi yapar
  const GetAllFilesButton = () => {
    dispatch(GetAllPackages());
    dispatch(GetAllFiles());
    history.push("/adminfiles");
  };
  //Robota bağlı programları çeker ve sayfa yönlendirmesi yapar
  const GetPrograms_ = (robotname, useraccessid) => {
    dispatch(GetRobotPrograms(robotname, useraccessid));
    history.push("/programs");
  };
  //Yeni bir aros oluşturmak için istek atar ve sayfaya yönlendirir
  const CreatArosButton = () => {
    dispatch(GetAllRobotsTester());
    history.push("/createaros");
  };

  //Çıkış yapma fonksiyonu
  const Exit = () => {
    dispatch(LogOut());
    history.push("/login");
  };

  // const styles = {
  //   SideBarHeader: {
  //     display: "flex",
  //     alignItems: "flex-end",
  //     justifyContent: "flex-end",
  //     flexDirection: "column",
  //   },
  //   SideBarHeaderIcon: {
  //     fontSize: 30,
  //     direction: "flex",
  //     justifyItems: "flex-end",
  //     justifyContent: "flex-end",
  //     marginRight: 10,
  //     marginTop: 10,
  //   },
  //   SideBarBottom: {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     flexDirection: "column",
  //     visibility: Profile,
  //   },
  //   SideBarImage: {
  //     height: 90,
  //     width: 90,
  //     opacity: 0.5,
  //     bottom: 0,
  //     right: 0,
  //     borderRadius: 120,
  //     borderWidth: 2,
  //     borderColor: "white",
  //   },
  //   SideBarMail: {
  //     fontSize: 12,
  //     display: "flex",
  //     flexDirection: "row",
  //     alignItems: "flex-start",
  //   },
  //   SideBarPhone: {
  //     marginLeft: 20,
  //     fontSize: 12,
  //     display: "flex",
  //     flexDirection: "row",
  //     alignItems: "flex-end",
  //   },
  //   SideBarLogo: {
  //     padding: "17px",
  //     textTransform: "uppercase",
  //     fontWeight: "bold",
  //     fontSize: 14,
  //     letterSpacing: "1px",
  //     overflow: "hidden",
  //     textOverflow: "ellipsis",
  //     whiteSpace: "nowrap",
  //     height: "5%",
  //   },
  // };
  const styles = {
    SideBarHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    SideBarHeadertoggle: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    SideBarHeaderIcon: {
      fontSize: 30,
      direction: "flex",
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      // marginRight: 10,
      //marginTop: 10,
    },
    SideBarBottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      visibility: Profile,
    },
    SideBarImage: {
      height: 90,
      width: 90,
      opacity: 0.5,
      bottom: 0,
      right: 0,
      borderRadius: 120,
      borderWidth: 2,
      borderColor: "white",
    },
    SideBarMail: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    SideBarPhone: {
      marginLeft: 20,
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    SideBarLogo: {
      padding: "17px",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "1px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginLeft: "10px",
      //  height: "5%",
    },
  };
  return (
    <div
      style={{ height: "100%", boxShadow: "8px 5px 11px 2px rgba(0,0,0,0.45)" }}
    >
      <ProSidebar
        toggled={false}
        collapsed={SidebarToogleUser.state}
        color="red"
      >
        <SidebarHeader>
          {!SidebarToogleUser.state ? (
            <div
              style={styles.SideBarLogo}
              onClick={() => history.push("/home")}
            >
              Roboliza
            </div>
          ) : (
            <div
              style={styles.SideBarLogo}
              onClick={() => history.push("/home")}
            >
              <FontAwesomeIcon
                icon={faHome}
                onClick={() => dispatch(SidebarState(!SidebarToogleUser.state))}
                style={{
                  direction: "flex",
                  justifyItems: "flex-end",
                  justifyContent: "flex-end",
                }}
                size="2x"
              />
            </div>
          )}
        </SidebarHeader>
        <SidebarHeader style={{ backgroundColor: "#2b2b2b" }}>
          <div
            style={
              SidebarToogleUser.state
                ? styles.SideBarHeader
                : { ...styles.SideBarHeadertoggle, marginRight: "10px" }
            }
          >
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => dispatch(SidebarState(!SidebarToogleUser.state))}
              style={styles.SideBarHeaderIcon}
            />
          </div>

          {SidebarToogleUser.state === false ? (
            <div style={styles.SideBarBottom}>
              <Avatar
                size={100}
                icon={
                  <MdEngineering
                    style={{
                      color: "#1d1d1d",
                      marginLeft: 25,
                      marginTop: 25,
                    }}
                  />
                }
              />
              <div>
                <p
                  style={{ fontWeight: "bold", fontSize: 12, marginTop: "3%" }}
                >
                  {User.User.Name} {User.User.Surname}
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: "3%",
                  fontSize: 12,
                  marginBottom: "3%",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle" title={"Profil"}>
            <SubMenu
              title="Robotlarım"
              icon={
                <Badge
                  count={User.User.UserAccesses.length}
                  size="small"
                  color="grey"
                >
                  <FontAwesomeIcon icon={faRobot} />
                </Badge>
              }
            >
              {User.User.UserAccesses.map((Robot) => (
                <MenuItem
                  key={Robot.ProducedRobots.Robot.Model}
                  onClick={() =>
                    GetPrograms_(
                      Robot.ProducedRobots.Robot.Model,
                      Robot.UserAccessId
                    )
                  }
                  icon={<FontAwesomeIcon icon={faRobot} />}
                >
                  {Robot.RobotName}
                </MenuItem>
              ))}
            </SubMenu>

            <MenuItem
              onClick={() => CreatArosButton()}
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              Aros Oluştur
            </MenuItem>
            <MenuItem
              onClick={() => GetAllFilesButton()}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              Özel Yazılımlar
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <FaSignInAlt
              style={{ fontSize: 19, marginRight: 3 }}
              onClick={() => Exit()}
            />
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              visibility={Profile}
            ></span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

import React, { useEffect } from "react";
import "react-awesome-slider/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetAllMarketingFiles } from "../../redux/actions/taskActions";
import AdminSideBar from "./AutorizeSideBar/AdminSideBar";
import DeveloperSideBar from "./AutorizeSideBar/DeveloperSideBar";
import MarkettingSideBar from "./AutorizeSideBar/MarkettingSideBar";
import TesterSideBar from "./AutorizeSideBar/TesterSideBar";
export default function SideBar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const User = useSelector((state) => state.User);

  // Gelen authorized işlemine göre yönlendirme yapan fonksiyon
  const SideBarComponent = ({ user }) => {
    if (user === "Customer") {
      return "";
    } else if (user === "Developer") {
      return <DeveloperSideBar></DeveloperSideBar>;
    } else if (user === "Marketting") {
      return <MarkettingSideBar></MarkettingSideBar>;
    } else if (user === "Tester") {
      return <TesterSideBar></TesterSideBar>;
    } else if (user === "Admin" || user === "SuperAdmin") {
      return <AdminSideBar></AdminSideBar>;
    } else {
      history.push("/login");
    }
  };

  //ilk başta markettin dosylarına istek atıyor
  useEffect(() => {
    dispatch(GetAllMarketingFiles());
  }, []);
  return (
    <div>
      {User.role === undefined ? (
        history.push("/login")
      ) : (
        <SideBarComponent user={User.role}></SideBarComponent>
      )}
    </div>
  );
}

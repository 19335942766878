import React from "react";
import "../Loading/Loading.css";
export default function Loading() {
  return (
    <div
      style={{
        backgroundColor: "#131417",
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <svg
        width="200"
        height="200"
        viewBox="0 0 100 100"
        className="stroke-still-svg"
      >
        <polyline
          className="line-cornered stroke-still"
          points="0,0 100,0 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
        <polyline
          className="line-cornered stroke-still"
          points="0,0 0,100 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>

        <polyline
          className="line-cornered stroke-animation"
          points="0,0 100,0 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
        <polyline
          className="line-cornered stroke-animation"
          points="0,0 0,100 100,100"
          strokeWidth="10"
          fill="none"
        ></polyline>
      </svg>
    </div>
  );
}

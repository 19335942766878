import { ROBOT_AROS_ALL } from "../actions/actions";

const INITIAL_STATE = {
  RobotAros: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROBOT_AROS_ALL: {
      return {
        ...state,
        RobotAros: action.aros,
      };
    }
    default:
      return state;
  }
};

import { ALL_ROBOTS_TABLE } from "../actions/actions";

const INITIAL_STATE = {
  Robots: [],
  Type: [],
  Loading: false,
  state: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_ROBOTS_TABLE: {
      return {
        ...state,
        Robots: action.Robots,
        Type: action.Type,
        Loading: action.Loading,
      };
    }
    default:
      return state;
  }
};

import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faHome,
  faRobot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "react-awesome-slider/dist/styles.css";
// import background from "../assets/images/arkaPlan.jpg";
import { FaSignInAlt } from "react-icons/fa";
import { RiShoppingCartFill } from "react-icons/ri";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogOut } from "../../../redux/actions/LoginAction";
import {
  GetAllRobotModelTable,
  GetAllRobotsTable,
  GetAllUserSelectBox,
  GetAllUserTable,
  GetUserRole,
  SidebarState,
} from "../../../redux/actions/taskActions";
export default function MarkettingSideBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [SideBar, SetSideBar] = useState(false);
  const [Profile, SetProfile] = useState("visible");
  const User = useSelector((state) => state.User);
  const SidebarToogleUser = useSelector((state) => state.SidebarToogle);
  useEffect(() => {
    if (SideBar === true) {
      SetProfile("hidden");
    } else {
      SetProfile("visible");
    }
  }, [SideBar]);
  function GetAllRobotModeltButton() {
    dispatch(GetAllRobotModelTable());
    history.push("/adminrobotmodel");
  }
  //Tüm Robotları Çeker
  const GetAllRobots = () => {
    dispatch(GetAllUserSelectBox());
    dispatch(GetAllRobotsTable());
    history.push("/adminrobots");
  };

  //Tüm Kullanıcıları Çeker
  const GetAllUsers = () => {
    dispatch(GetAllUserTable());
    dispatch(GetUserRole());
    history.push("/adminusers");
  };

  //Çıkış yapma fonksiyonu
  const Exit = () => {
    dispatch(LogOut());
    history.push("/login");
  };

  // const styles = {
  //   SideBarHeader: {
  //     display: "flex",
  //     alignItems: "flex-end",
  //     justifyContent: "flex-end",
  //     flexDirection: "column",
  //   },
  //   SideBarHeaderIcon: {
  //     fontSize: 30,
  //     direction: "flex",
  //     justifyItems: "flex-end",
  //     justifyContent: "flex-end",
  //     marginRight: 10,
  //     marginTop: 10,
  //   },
  //   SideBarBottom: {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     flexDirection: "column",
  //     visibility: Profile,
  //   },
  //   SideBarImage: {
  //     height: 90,
  //     width: 90,
  //     opacity: 0.5,
  //     bottom: 0,
  //     right: 0,
  //     borderRadius: 120,
  //     borderWidth: 2,
  //     borderColor: "white",
  //   },
  //   SideBarMail: {
  //     fontSize: 12,
  //     display: "flex",
  //     flexDirection: "row",
  //     alignItems: "flex-start",
  //   },
  //   SideBarPhone: {
  //     marginLeft: 20,
  //     fontSize: 12,
  //     display: "flex",
  //     flexDirection: "row",
  //     alignItems: "flex-end",
  //   },
  //   SideBarLogo: {
  //     padding: "17px",
  //     textTransform: "uppercase",
  //     fontWeight: "bold",
  //     fontSize: 14,
  //     letterSpacing: "1px",
  //     overflow: "hidden",
  //     textOverflow: "ellipsis",
  //     whiteSpace: "nowrap",
  //     height: "5%",
  //   },
  // };
  const styles = {
    SideBarHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    SideBarHeadertoggle: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    SideBarHeaderIcon: {
      fontSize: 30,
      direction: "flex",
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      // marginRight: 10,
      //marginTop: 10,
    },
    SideBarBottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      visibility: Profile,
    },
    SideBarImage: {
      height: 90,
      width: 90,
      opacity: 0.5,
      bottom: 0,
      right: 0,
      borderRadius: 120,
      borderWidth: 2,
      borderColor: "white",
    },
    SideBarMail: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    SideBarPhone: {
      marginLeft: 20,
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    SideBarLogo: {
      padding: "17px",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "1px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginLeft: "10px",
      //  height: "5%",
    },
  };
  return (
    <div
      style={{ height: "100%", boxShadow: "8px 5px 11px 2px rgba(0,0,0,0.45)" }}
    >
      <ProSidebar
        toggled={false}
        collapsed={SidebarToogleUser.state}
        color="red"
      >
        <SidebarHeader>
          {!SidebarToogleUser.state ? (
            <div
              style={styles.SideBarLogo}
              onClick={() => history.push("/home")}
            >
              Roboliza
            </div>
          ) : (
            <div
              style={styles.SideBarLogo}
              onClick={() => history.push("/home")}
            >
              <FontAwesomeIcon
                icon={faHome}
                onClick={() => dispatch(SidebarState(!SidebarToogleUser.state))}
                style={{
                  direction: "flex",
                  justifyItems: "flex-end",
                  justifyContent: "flex-end",
                }}
                size="2x"
              />
            </div>
          )}
        </SidebarHeader>

        <SidebarHeader style={{ backgroundColor: "#2b2b2b" }}>
          <div
            style={
              SidebarToogleUser.state
                ? styles.SideBarHeader
                : { ...styles.SideBarHeadertoggle, marginRight: "10px" }
            }
          >
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => dispatch(SidebarState(!SidebarToogleUser.state))}
              style={styles.SideBarHeaderIcon}
            />
          </div>

          {SidebarToogleUser.state === false ? (
            <div style={styles.SideBarBottom}>
              <Avatar
                size={100}
                icon={
                  <RiShoppingCartFill
                    style={{
                      color: "#1d1d1d",
                      marginLeft: 23,
                      marginTop: 23,
                    }}
                  />
                }
              />
              <div>
                <p
                  style={{ fontWeight: "bold", fontSize: 12, marginTop: "3%" }}
                >
                  {User.User.Name} {User.User.Surname}
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: "3%",
                  fontSize: 12,
                  marginBottom: "3%",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <MenuItem
              onClick={() => GetAllUsers()}
              icon={<FontAwesomeIcon icon={faUser} />}
            >
              Kullanıcılar
            </MenuItem>
            <MenuItem
              onClick={() => GetAllRobots()}
              icon={<FontAwesomeIcon icon={faRobot} />}
            >
              Robotlar
            </MenuItem>
            <MenuItem
              onClick={() => GetAllRobotModeltButton()}
              icon={<FontAwesomeIcon icon={faBookmark} />}
            >
              Robot Modelleri
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <FaSignInAlt
              style={{ fontSize: 19, marginRight: 3 }}
              onClick={() => Exit()}
            />
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              visibility={Profile}
            ></span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import {
  faAtom,
  faBars,
  faCode,
  faCodeBranch,
  faCogs,
  faFileCode,
  faHome,
  faRobot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Spin } from "antd";
import React, { useState } from "react";
import "react-awesome-slider/dist/styles.css";

import { FaSignInAlt } from "react-icons/fa";
import { MdDeveloperMode } from "react-icons/md";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { LogOut } from "../../../redux/actions/LoginAction";
import { SidebarState } from "../../../redux/actions/SideBarAction";
import {
  GetAllFiles,
  GetAllPackages,
  GetAllPackagesTable,
  GetAllRobotModelTable,
  GetAllRobotsTable,
  GetAllUserSelectBox,
  GetAllUserTable,
  GetUserRole,
} from "../../../redux/actions/taskActions";

export default function AdminSideBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [SideBar, SetSideBar] = useState(true);
  const [Profile, SetProfile] = useState("visible");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const User = useSelector((state) => state.User);
  const SidebarToogleUser = useSelector((state) => state.SidebarToogle);

  //Bütün Robot Modellere İstek Atar ve Sayfayı açar
  function GetAllRobotModeltButton() {
    dispatch(GetAllRobotModelTable());
    history.push("/adminrobotmodel");
  }
  //Bütün Robot Türlerine İstek Atar ve Sayfayı açar
  const GetAllRobots = () => {
    dispatch(GetAllUserSelectBox());
    dispatch(GetAllRobotsTable());
    history.push("/adminrobots");
  };
  //Bütün Programlara İstek Atar ve Sayfayı açar
  const GetAllPrograms = () => {
    dispatch(GetAllPackagesTable());
    history.push("/adminprograms");
  };
  //Bütün Dosyalar İstek Atar ve Sayfayı açar
  const GetAllFilesButton = () => {
    dispatch(GetAllPackages());
    dispatch(GetAllFiles());
    history.push("/adminfiles");
  };
  //Bütün Kullanıcılara İstek Atar ve Sayfayı açar
  const GetAllUsers = () => {
    dispatch(GetAllUserTable());
    dispatch(GetUserRole());
    history.push("/adminusers");
  };
  //Bütün Paketlere İstek Atar ve Sayfayı açar
  const GetAllPackageClick = () => {
    dispatch(GetAllPackages());
    history.push("/uploadsoftware");
  };

  //Bütün Paketlere İstek Atar ve Sayfayı açar
  const GetProfil = () => {
    dispatch(GetAllPackages());
    history.push("/profil");
  };

  //Çıkış yapma fonksiyonu
  const Exit = () => {
    dispatch(LogOut());
    history.push("/login");
  };

  const styles = {
    SideBarHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    SideBarHeadertoggle: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    SideBarHeaderIcon: {
      fontSize: 30,
      direction: "flex",
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      // marginRight: 10,
      //marginTop: 10,
    },
    SideBarBottom: {
      display: "flex",

      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      visibility: Profile,
    },
    SideBarImage: {
      height: 90,
      width: 90,
      opacity: 0.5,
      bottom: 0,
      right: 0,
      borderRadius: 120,
      borderWidth: 2,
      borderColor: "white",
    },
    SideBarMail: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    SideBarPhone: {
      marginLeft: 20,
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    SideBarLogo: {
      padding: "18px",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "1px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginLeft: "10px",
      //  height: "5%",
    },
  };
  return (
    <div
      style={{ height: "100%", boxShadow: "8px 5px 11px 2px rgba(0,0,0,0.45)" }}
    >
      {isDesktopOrLaptop && (
        <ProSidebar
          toggled={false}
          collapsed={SidebarToogleUser.state}
          color="red"
        >
          <SidebarHeader>
            {!SidebarToogleUser.state ? (
              <div
                style={styles.SideBarLogo}
                onClick={() => history.push("/home")}
              >
                Roboliza
              </div>
            ) : (
              <div
                style={styles.SideBarLogo}
                onClick={() => history.push("/home")}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  onClick={() =>
                    dispatch(SidebarState(!SidebarToogleUser.state))
                  }
                  style={{
                    direction: "flex",
                    justifyItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                  size="2x"
                />
              </div>
            )}
          </SidebarHeader>

          <SidebarHeader style={{ backgroundColor: "#2b2b2b" }}>
            <div
              style={
                SidebarToogleUser.state
                  ? styles.SideBarHeader
                  : { ...styles.SideBarHeadertoggle, marginRight: "10px" }
              }
            >
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => dispatch(SidebarState(!SidebarToogleUser.state))}
                style={styles.SideBarHeaderIcon}
              />
            </div>

            {SidebarToogleUser.state === false ? (
              <div style={styles.SideBarBottom}>
                <Avatar
                  onClick={() => GetProfil()}
                  size={100}
                  icon={
                    <MdDeveloperMode
                      style={{
                        color: "#1d1d1d",
                        marginLeft: 23,
                        marginTop: 23,
                      }}
                    />
                  }
                />
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 12,
                    marginTop: "3%",
                  }}
                >
                  <div>{User.User.Name}</div>
                  <div>{User.User.Surname}</div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "3%",
                    fontSize: 12,
                    marginBottom: "3%",
                  }}
                ></div>
              </div>
            ) : (
              ""
            )}
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem
                onClick={() => GetAllRobots()}
                icon={<FontAwesomeIcon icon={faRobot} />}
              >
                Robotlar
              </MenuItem>

              <MenuItem
                onClick={() => GetAllUsers()}
                icon={<FontAwesomeIcon icon={faUser} />}
              >
                Kullanıcılar
              </MenuItem>

              <MenuItem
                onClick={() => GetAllPrograms()}
                icon={<FontAwesomeIcon icon={faFileCode} />}
              >
                Yazılımlar
              </MenuItem>

              <MenuItem
                onClick={() => GetAllFilesButton()}
                icon={<FontAwesomeIcon icon={faCodeBranch} />}
              >
                Versiyonlar
              </MenuItem>

              <MenuItem
                onClick={() => GetAllRobotModeltButton()}
                icon={<FontAwesomeIcon icon={faBookmark} />}
              >
                Robot Modelleri
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter style={{ textAlign: "center" }}>
            <div
              className="sidebar-btn-wrapper"
              style={{
                padding: "20px 24px",
              }}
              onClick={() => Exit()}
            >
              <FaSignInAlt
                style={{ fontSize: 19, marginRight: 3, cursor: "pointer" }}
                title="Çıkış Yap"
              ></FaSignInAlt>

              <span visibility={Profile}></span>
            </div>
          </SidebarFooter>
        </ProSidebar>
      )}

      {isTabletOrMobile && (
        <ProSidebar toggled={false} collapsed={SideBar} color="red">
          <div className=" sidebar-item sidebar-menu"></div>

          <SidebarContent>
            <Menu iconShape="circle">
              <MenuItem
                onClick={() => GetAllRobots()}
                icon={<FontAwesomeIcon icon={faAtom} />}
              >
                Robotlar
              </MenuItem>

              <MenuItem
                onClick={() => GetAllUsers()}
                icon={<FontAwesomeIcon icon={faAtom} />}
              >
                Kullanıcılar
              </MenuItem>
            </Menu>
            <Menu iconShape="square">
              <SubMenu
                title="Robot İşlemleri"
                icon={<FontAwesomeIcon icon={faCode} />}
              >
                <MenuItem
                  onClick={() => GetAllPackageClick()}
                  icon={<FontAwesomeIcon icon={faAtom} />}
                >
                  Yazılım Yükle
                </MenuItem>
              </SubMenu>
            </Menu>
          </SidebarContent>
          <SidebarFooter style={{ textAlign: "center" }}>
            <div
              className="sidebar-btn-wrapper"
              style={{
                padding: "20px 24px",
              }}
              onClick={() => Exit()}
            >
              <FaSignInAlt style={{ fontSize: 19, marginRight: 3 }} />

              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                visibility={Profile}
              >
                {SidebarToogleUser.state === true ? " Çıkış Yap" : ""}
              </span>
            </div>
          </SidebarFooter>
        </ProSidebar>
      )}
    </div>
  );
}

import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { CloudUrl } from "../../services/GlobalVariables";
import ErrorMessage from "../../services/MessageServices";
import OperatinSystemServices from "../../services/OperatinSystemServices";
import {
  ALL_DOWLOANDFİLES,
  ALL_FİLES_TABLE,
  ALL_MARKETTİNG,
  ALL_PACKAGES_TABLE,
  ALL_PRODUCE_ROBOT,
  ALL_ROBOTS,
  ALL_ROBOTS_TABLE,
  ALL_ROBOT_MODEL_TABLE,
  ALL_SHOW_PROGRAMS,
  ALL_STORE_PROGRAMS_TABLE,
  ALL_STORE_TABLE,
  ALL_TESTER_TABLE,
  ALL_USERS,
  AROS_PROGRAMS,
  AROS_TESTER,
  AROS_VERSİON,
  DOWNLOAD_PROCESS,
  FOLDER_INFO,
  FİLE_DESCRİPTİON,
  FİLE_DESCRİPTİON_DATA,
  GET_ALL_PROGRAMS,
  GET_USERS,
  MODAL_STATE,
  PACKAGES_CODE,
  PACKAGES_INFO,
  PACKAGE_WİTH_ROBOTS,
  PROGRAMS_INFO,
  ROBOT_AROS_ALL,
  ROBOT_PACKAGES,
  ROBOT_RUN_TIME,
  ROBOT_TYPE,
  SPİNNER,
  SİDEBAR_STATE,
  UPDATES_INFO,
  UPDATE_STATE,
  USER_INFO,
  USER_ROLE,
  USER_SELECT,
  VERSİONS_INFO,
} from "./actions";

var md5 = require("md5");
const axios = require("axios");

export const SidebarState = (state) => (dispatch) => {
  dispatch({
    type: SİDEBAR_STATE,
    state: state,
  });
};

export const LogOut = () => async (dispatch) => {
  localStorage.removeItem("jwtHeader");

  dispatch({
    type: USER_INFO,

    state: false,
  });
};

export const LoginControl = (UserName, Password) => async (dispatch) => {
  await axios

    .post(CloudUrl + `/api/user/SignIn`, {
      UserName: UserName,
      Password: md5(Password),
    })
    .then((res) => {
      const token = res.data;
      if (token.Check === undefined) {
        //User Tokenimizi reduxa kayıt ediyoruz.
        //
        var decoded = jwt_decode(token);
        var userid = decoded.UserId;

        //Config Dosyamızı local store atıyoruz.
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        localStorage.setItem("jwtHeader", token);

        axios
          .get(CloudUrl + `/api/user/getUserInformations/` + userid, config)
          .then((res) => {
            const user = res.data;
            if (user.Check === false) {
              ErrorMessage(
                user.NormalMessage,
                user.DeveloperMessage,
                user.Type
              );
            } else {
              dispatch({
                type: USER_INFO,
                User: user,
                token: token,
                role: decoded.Roles,
                state: true,
              });
              // localStorage.setItem(
              //   "UserAccessId",
              //   user.UserAccesses[0].UserAccessId
              // );
            }
          })
          .catch((error) => {});
      } else {
        dispatch({
          type: USER_INFO,
          users: null,
          state: false,
        });
        toast.error("Şifre veya Kullanıcı Adı Hatalı", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          limit: 1,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: USER_INFO,

        state: false,
      });
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        limit: 1,
      });
    });
};

const Config = (token) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};

export const ClearDescription = () => (dispatch) => {
  dispatch({
    type: FİLE_DESCRİPTİON,
    FileDescription: null,
  });
};

export const GetAllRobotsTester = (robotid, List) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/robot/getallrobots",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const robots = res.data;

      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
      } else {
        dispatch({
          type: ALL_ROBOTS,
          Robots: robots,
        });
      }
      console.log(robots, "robots");
      if (robots.length !== 0) {
        axios
          .post(
            CloudUrl +
              "/api/package/GetRobotPackagesWithCreateAros/" +
              robots[0].RobotId,
            {
              RobotId: robotid,
              List: List,
            },

            Config(localStorage.getItem("jwtHeader").toString())
          )
          .then((res) => {
            const aros = res.data;

            const sa = (data) => {
              if (data) {
                data.forEach((element) => {
                  if (element.Files) {
                    element.key = "file" + element.key;
                    element.disabled = true;
                    element.Files.forEach((file) => {
                      element.children.push(file);
                    });
                  }
                  return sa(element.children);
                });
              }
              return data;
            };
            if (aros.Check === false) {
              ErrorMessage(
                aros.NormalMessage,
                aros.DeveloperMessage,
                aros.Type
              );
              dispatch({
                type: SPİNNER,
                Spinner: false,
              });
            } else {
              dispatch({
                type: SPİNNER,
                Spinner: false,
              });

              return dispatch({
                type: AROS_TESTER,
                ArosTree: sa(aros.TreeData),
                ArosChecked: aros.CheckedFiles,
              });
            }
          });
      } else {
        ErrorMessage(
          "Şuan Kayılı Hiçbir Robot Bulunmamaktadır.",
          "Aktif olması için lütfen robot ekleyiniz",
          "error"
        );
      }
    });
};

export const GetAllRobots = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/robot/getallrobots",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const robots = res.data;
      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
      } else {
        dispatch({
          type: ALL_ROBOTS,
          Robots: robots,
        });
      }
      axios
        .get(
          CloudUrl + "/api/package/getrobotpackages/" + robots[0].RobotId,
          Config(localStorage.getItem("jwtHeader").toString())
        )
        .then((res) => {
          const packages = res.data;
          if (packages.Message != null) {
            toast.error(packages.Message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              limit: 1,
            });
          } else {
            const sa = (data) => {
              if (data) {
                data.forEach((element) => {
                  if (element.Files) {
                    element.key = "file" + element.key;
                    element.Files.forEach((file) => {
                      element.children.push(file);
                    });
                  }
                  return sa(element.children);
                });
              }
              return data;
            };
            dispatch({
              type: ROBOT_PACKAGES,
              Packages: sa(packages),
            });
          }
        });
    });
};

//[ADMİN]Admin Tarından User Tabosunu Dolduran İstek

export const GetAllRobotsTable = () => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/robot/getallrobotstable/",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const robots = res.data;

      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ALL_ROBOTS_TABLE,
          Robots: robots,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

//[Admin][Robots] Produce robotları doluduran istek
export const GetAllProduceRobotsTable = (robotid) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/robot/GetProducedRobots/" + robotid,
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const robots = res.data;
      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
      } else {
        dispatch({
          type: ALL_PRODUCE_ROBOT,
          Robots: robots,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetBindUserAccess =
  (UserAccessId, UserId, RobotId) => (dispatch) => {
    axios
      .get(
        CloudUrl +
          "/api/useraccess/UpdateUserAccess/" +
          UserAccessId +
          "/" +
          UserId +
          "/" +
          RobotId,
        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const robots = res.data;
        if (robots.Check === false) {
          ErrorMessage(
            robots.NormalMessage,
            robots.DeveloperMessage,
            robots.Type
          );
        } else {
          dispatch({
            type: ALL_PRODUCE_ROBOT,
            Robots: robots,
          });
        }
      });
  };

export const PostUpdateProductRobot =
  (RobotName, Color, UserId, RobotId, UserAccessId) => (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .post(
        CloudUrl + "/api/useraccess/UpdateUserAccess/" + RobotId,
        {
          RobotName: RobotName,
          ColorHexCode: Color,
          UserId: UserId,
          UserAccessId: UserAccessId,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const robots = res.data;
        if (robots.Check === false) {
          ErrorMessage(
            robots.NormalMessage,
            robots.DeveloperMessage,
            robots.Type
          );
        } else {
          dispatch({
            type: ALL_PRODUCE_ROBOT,
            Robots: robots,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      });
  };

export const PostAddProductRobot =
  (RobotName, Color, RobotUniqueId, RobotId) => (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .post(
        CloudUrl + "/api/robot/AddProducedRobot",
        {
          RobotName: RobotName,
          RobotId: RobotId,
          ColorHexCode: Color,
          RobotUniqueId: RobotUniqueId,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const robots = res.data;

        if (robots.Check === false) {
          ErrorMessage(
            robots.NormalMessage,
            robots.DeveloperMessage,
            robots.Type
          );
        } else {
          dispatch({
            type: ALL_PRODUCE_ROBOT,
            Robots: robots,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      });
  };

export const GetSwitchRobotProduceStatus =
  (UserAccessId, RobotId) => (dispatch) => {
    axios
      .get(
        CloudUrl +
          "/api/useraccess/switchstatus/" +
          UserAccessId +
          "/" +
          RobotId,

        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const robots = res.data;
        if (robots.Check === false) {
          ErrorMessage(
            robots.NormalMessage,
            robots.DeveloperMessage,
            robots.Type
          );
        } else {
          dispatch({
            type: ALL_PRODUCE_ROBOT,
            Robots: robots,
          });
        }
      });
  };

export const GetAllUserSelectBox = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/user/GetAllUserSelectBox",
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const users = res.data;
      if (users.Check === false) {
        ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
      } else {
        dispatch({
          type: USER_SELECT,
          Users: users,
        });
      }
    });
};

export const GetAllUserTable = () => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/user/getallusertable/",
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const users = res.data;
      if (users.Check === false) {
        ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
      } else {
        dispatch({
          type: ALL_USERS,
          Users: users,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetUserUpdate = (userid) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/user/update/" + userid,
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const users = res.data;
      if (users.Check === false) {
        ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
      } else {
        dispatch({
          type: ALL_USERS,
          Users: users,
        });
      }
    });
};

export const GetUserDescriptionUpdate =
  (
    UserName,
    Email,
    Name,
    SurName,
    UserRoleId,
    Address,
    PhoneNumber,
    Password
  ) =>
  (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .post(
        CloudUrl + "/api/user/adduser/",
        {
          Name: Name,
          SurName: SurName,
          Email: Email,
          UserName: UserName,
          UserRoleId: UserRoleId,
          Address: Address,
          PhoneNumber: PhoneNumber,
          Password: Password,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const users = res.data;
        if (users.Check === false) {
          ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
        } else {
          dispatch({
            type: ALL_USERS,
            Users: users,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
          ErrorMessage(
            "Kullanıcı Başarılı bir şekilde eklendi.",
            "",
            "success"
          );
        }
      });
  };

export const PostUpdateUser =
  (UserId, UserName, Email, Name, SurName, UserRoleId, Address, PhoneNumber) =>
  (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .post(
        CloudUrl + "/api/user/updateuser/",
        {
          UserId: UserId,
          Name: Name,
          SurName: SurName,
          Email: Email,
          UserName: UserName,
          UserRoleId: UserRoleId,
          Address: Address,
          PhoneNumber: PhoneNumber,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const users = res.data;
        if (users.Check === false) {
          ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
        } else {
          dispatch({
            type: ALL_USERS,
            Users: users,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      });
  };
export const GetUserRole = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/role/GetAllRoles",
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const roles = res.data;
      if (roles.Check === false) {
        ErrorMessage(roles.NormalMessage, roles.DeveloperMessage, roles.Type);
      } else {
        dispatch({
          type: USER_ROLE,
          Roles: roles,
        });
      }
    });
};

export const GetSaveArosPackage = (robotid, arosid, UserId) => (dispatch) => {
  console.log("UserId: ", UserId);
  axios
    .get(
      CloudUrl +
        "/api/file/SaveArosPackage/" +
        robotid +
        "/" +
        arosid +
        "/" +
        UserId,
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const robots = res.data;
      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
      } else {
        dispatch({
          type: ALL_ROBOTS_TABLE,
          Robots: robots,
        });
      }
    });
};

export const GetSwitchRobotStatus = (robotid) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/robot/SwitchRobotStatus/" + robotid,
      Config(localStorage.getItem("jwtHeader").toString())
    )

    .then((res) => {
      const robots = res.data;
      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
      } else {
        dispatch({
          type: ALL_ROBOTS_TABLE,
          Robots: robots,
        });
      }
    });
};

export const PostUpdateRobot =
  (RobotId, Description, RobotTypeId, Model) => (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });

    axios
      .post(
        CloudUrl + "/api/robot/updaterobot/",
        {
          RobotId: RobotId,
          Description: Description,
          RobotTypeName: RobotTypeId,
          Model: Model,
        },

        Config(localStorage.getItem("jwtHeader").toString())
      )

      .then((res) => {
        const robots = res.data;
        if (robots.Check === false) {
          ErrorMessage(
            robots.NormalMessage,
            robots.DeveloperMessage,
            robots.Type
          );
        } else {
          dispatch({
            type: ALL_ROBOTS_TABLE,
            Robots: robots,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      });
  };

export const GetRobotsPackages = (robotid) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/package/getrobotpackages/" + robotid,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      const sa = (data) => {
        if (data) {
          data.forEach((element) => {
            if (element.Files) {
              element.key = "file" + element.key;
              element.Files.forEach((file) => {
                element.children.push(file);
              });
            }
            return sa(element.children);
          });
        }
        return data;
      };
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ROBOT_PACKAGES,
          Packages: sa(packages),
          message: packages.Message,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetArosPackages = (robotid) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/GetArosfilerobot/" + robotid,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      const sa = (data) => {
        if (data) {
          data.forEach((element) => {
            if (element.Files) {
              element.key = "file" + element.key;
              element.Files.forEach((file) => {
                element.children.push(file);
              });
            }
            return sa(element.children);
          });
        }
        return data;
      };
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
      } else {
        dispatch({
          type: ROBOT_PACKAGES,
          Packages: sa(packages),
          message: packages.Message,
        });
      }
    });
};

export const TesterComment =
  (FileId, Comment, PackageId, RobotModel) => async (dispatch) => {
    var decoded = jwt_decode(localStorage.getItem("jwtHeader"));
    var userid = decoded.UserId;
    try {
      axios
        .post(
          CloudUrl + `/api/testercomment/add`,
          {
            UserId: userid,
            FileId: FileId,
            Comment: Comment,
          },
          Config(localStorage.getItem("jwtHeader").toString())
        )
        .then((res) => {
          axios
            .get(
              CloudUrl + "/api/file/getfiledescription/" + FileId,
              Config(localStorage.getItem("jwtHeader").toString())
            )
            .then((res) => {
              const fildedescription = res.data;
              if (fildedescription.Check === false) {
                ErrorMessage(
                  fildedescription.NormalMessage,
                  fildedescription.DeveloperMessage,
                  fildedescription.Type
                );
              } else {
                dispatch({
                  type: FİLE_DESCRİPTİON,
                  FileDescription: fildedescription,
                });
              }
              axios
                .get(
                  CloudUrl +
                    "/api/package/getchildpackage/" +
                    PackageId +
                    "/" +
                    RobotModel,
                  Config(localStorage.getItem("jwtHeader").toString())
                )
                .then((res) => {
                  const packages = res.data;
                  if (packages.Check === false) {
                    ErrorMessage(
                      packages.NormalMessage,
                      packages.DeveloperMessage,
                      packages.Type
                    );
                  } else {
                    dispatch({
                      type: PACKAGES_INFO,
                      Packages: packages,
                    });
                  }
                });
            });
        });
    } catch (e) {}
  };

export const ArosProgramsAdd = (program) => async (dispatch) => {
  dispatch({
    type: AROS_PROGRAMS,
    Program: program,
  });
};

// export const APİ_CALL = async (URL, BODY = null) => {
//   axios(CloudUrl+`/api/` + URL, BODY).then((res) => {
//     return res;
//   });
// };

export const UploadPackage = (file) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });

  axios({
    url: CloudUrl + "/api/package/AddPackage",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("jwtHeader").toString(),
    },
    onUploadProgress: (progressEvent) =>
      dispatch({
        type: UPDATES_INFO,
        updates_time: Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ),
      }), //your url
    method: "POST",
    data: file,

    // .then((res) => {
    //   const users = res.data;
    //   if (users.Check === false) {
    //     ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
    //   } else {
    //     dispatch({
    //       type: ALL_USERS,
    //       Users: users,
    //     });
    //     ErrorMessage("Kullanıcı Başarılı bir şekilde eklendi.", "success");
    //   }
    // });
  }).then((res) => {
    const packages = res.data;
    if (packages.Check === false) {
      ErrorMessage(
        packages.NormalMessage,
        packages.DeveloperMessage,
        packages.Type
      );
      dispatch({
        type: ALL_DOWLOANDFİLES,
        Spinner: false,
      });
      dispatch({
        type: SPİNNER,
        Spinner: false,
      });
    } else {
      dispatch({
        type: ALL_PACKAGES_TABLE,
        Packages: packages,
      });
      dispatch({
        type: SPİNNER,
        Spinner: false,
      });
    }

    // dispatch({
    //   type: UPDATES_INFO,
    //   message: updates.Message,
    //   updates_time: updates.Message,
    // });
  });
};
export const GetPackageSwichtUpdate = (packageıd) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/package/ChangeStatu/" + packageıd,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
        dispatch({
          type: ALL_DOWLOANDFİLES,
          Spinner: false,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ALL_PACKAGES_TABLE,
          Packages: packages,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const UpdatePackage = (file) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });

  axios({
    url: CloudUrl + "/api/package/updatepackage",

    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("jwtHeader").toString(),
    },
    onUploadProgress: (progressEvent) =>
      dispatch({
        type: UPDATES_INFO,
        updates_time: Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ),
      }), //your url
    method: "POST",
    data: file,
  }).then((res) => {
    const packages = res.data;
    if (packages.Check === false) {
      ErrorMessage(
        packages.NormalMessage,
        packages.DeveloperMessage,
        packages.Type
      );
      dispatch({
        type: ALL_DOWLOANDFİLES,
        Spinner: false,
      });
      dispatch({
        type: SPİNNER,
        Spinner: false,
      });
    } else {
      dispatch({
        type: ALL_PACKAGES_TABLE,
        Packages: packages,
      });
      dispatch({
        type: SPİNNER,
        Spinner: false,
      });
    }

    // dispatch({
    //   type: UPDATES_INFO,
    //   message: updates.Message,
    //   updates_time: updates.Message,
    // });
  });
};

export const UploadFile =
  (PackageId, Version, EnvironmentId, file) => (dispatch) => {
    axios
      .post(
        CloudUrl + `/api/File/FileIsExist/`,
        {
          PackageId: PackageId,
          Version: Version,
          EnvironmentId: EnvironmentId,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const message = res.data;

        dispatch({
          type: UPDATES_INFO,
          updates_time: 0,
        });

        if (message.Check === true) {
          axios({
            url: CloudUrl + "/api/File/Add/",
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "Bearer " + localStorage.getItem("jwtHeader").toString(),
            },
            onUploadProgress: (progressEvent) =>
              dispatch({
                type: UPDATES_INFO,
                updates_time: Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                ),
                state: true,
              }), //your url
            method: "POST",
            data: file,
          }).then((res) => {
            const updates = res.data;
            if (updates.Check === false) {
              ErrorMessage(
                updates.NormalMessage,
                updates.DeveloperMessage,
                updates.Type
              );
              dispatch({
                type: UPDATES_INFO,
                state: false,
              });
            } else {
              dispatch({
                type: UPDATES_INFO,
                message: updates.Message,
                updates_time: updates.Message,
              });
            }
          });
        } else {
          if (message.Check === false) {
            ErrorMessage(
              message.NormalMessage,
              message.DeveloperMessage,
              message.Type
            );
            dispatch({
              type: UPDATES_INFO,
              state: false,
            });
          } else {
            dispatch({
              type: UPDATES_INFO,
              message: message.Message,
            });
          }
        }
      });
  };

export const DowloandRobotArosPackages = (robotname) => (dispatch) => {
  dispatch({
    type: DOWNLOAD_PROCESS,
    state: 0,
  });
  var services = OperatinSystemServices(window);

  console.log(
    ' localStorage.getItem("UserAccessId_Aros").toString(): ',
    localStorage.getItem("UserAccessId_Aros").toString()
  );
  axios
    .get(
      CloudUrl +
        `/api/File/CheckPlatform/` +
        robotname +
        "/" +
        localStorage.getItem("UserAccessId_Aros").toString() + //your url
        "/" +
        services,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const programs = res.data;
      if (programs.Check === false) {
        ErrorMessage(
          programs.NormalMessage,
          programs.DeveloperMessage,
          programs.Type
        );
      } else {
        axios({
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("jwtHeader").toString(),
          },
          url:
            CloudUrl +
            "/api/File/DownloadArosVersion/" +
            robotname +
            "/" +
            localStorage.getItem("UserAccessId_Aros").toString() + //your url
            "/" +
            services,
          method: "GET",
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch({
              type: DOWNLOAD_PROCESS,
              state: percentCompleted,
            });
            // you can use this to show user percentage of file downloaded
          }, //// important
        }).then((response) => {
          const dowloand = response.data;
          if (dowloand.Check === false) {
            ErrorMessage(
              dowloand.NormalMessage,
              dowloand.DeveloperMessage,
              dowloand.Type
            );
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "AROS.zip"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        });
      }
    });
};

export const DowloandRobotPackages = (fileid, NamePackage) => (dispatch) => {
  dispatch({
    type: DOWNLOAD_PROCESS,
    state: 0,
  });
  if (localStorage.getItem("jwtHeader") === null) {
    ErrorMessage("TOKEN HATASI", "", "error");
  } else {
    axios({
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtHeader").toString(),
      },
      url:
        CloudUrl +
        "/api/File/Download/" +
        fileid +
        "/" +
        localStorage.getItem("UserAccessId_Aros").toString(), //your url
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: DOWNLOAD_PROCESS,
          state: percentCompleted,
        });
        // you can use this to show user percentage of file downloaded
      }, // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", NamePackage); //or any other extension
      console.log("NamePackage: ", NamePackage);
      document.body.appendChild(link);
      link.click();
    });
  }
};

export const DowloandRobotPackagesAdmin =
  (fileid, NamePackage, UserId) => (dispatch) => {
    axios({
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtHeader").toString(),
      },
      url: CloudUrl + "/api/File/DownloadAdmin/" + fileid + "/" + UserId, //your url
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: DOWNLOAD_PROCESS,
          state: percentCompleted,
        });
        // you can use this to show user percentage of file downloaded
      }, // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", NamePackage); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
// export const GetProgramsPackages = (packages) => (dispatch) => {
//   dispatch({
//     type: PACKAGES_INFO,
//     Packages: packages,
//   });
// };

// export const GetProgramsPackages = (packagename) => (dispatch) => {
//   axios
//     .get(
//       CloudUrl+"/api/package/GetPackageWithRobots/" +
//         packagename,
//       Config(localStorage.getItem("jwtHeader").toString())
//     )
//     .then((res) => {
//       const robots = res.data;
//
//       dispatch({
//         type: PACKAGE_WİTH_ROBOTS,
//         Robots: robots,
//       });
//     });
// };

export const GetAllUsers = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/getallfilestable/",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const users = res.data;
      if (users.Check === false) {
        ErrorMessage(users.NormalMessage, users.DeveloperMessage, users.Type);
      } else {
        dispatch({
          type: ALL_USERS,
          Users: users,
        });
      }
    });
};

export const GetAllPrograms = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/getallfilestable/",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const programs = res.data;
      if (programs.Check === false) {
        ErrorMessage(
          programs.NormalMessage,
          programs.DeveloperMessage,
          programs.Type
        );
      } else {
        dispatch({
          type: GET_ALL_PROGRAMS,
          Programs: programs,
        });
      }
    });
};

export const GetFilesTable = (robotid) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/getallfilestable/" + robotid,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      console.log("res.data: ", res.data);
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        dispatch({
          type: ALL_FİLES_TABLE,
          Folder: files,
        });
      }
      ///////////////////
    });
};
//[Admin][Robots] Aros Versiyonu ilk başta dolduran istek
export const GetFilesArosTable = (robotid) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios

    .get(
      CloudUrl + "/api/file/getallfilesoftherobottable/" + robotid,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        dispatch({
          type: ALL_FİLES_TABLE,
          Folder: files,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetLastArosVersion = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/getlastarosversion/",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const Aros = res.data;
      if (Aros.Check === false) {
        ErrorMessage(Aros.NormalMessage, Aros.DeveloperMessage, Aros.Type);
      } else {
        dispatch({
          type: AROS_VERSİON,
          Aros: Aros,
        });
      }
    });
};

export const getAddFileToRobot = (robotid, fileid) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  dispatch({
    type: ROBOT_PACKAGES,
    state: false,
  });
  axios
    .get(
      CloudUrl + "/api/file/AddFileToRobot/" + robotid + "/" + fileid,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;

      const sa = (data) => {
        if (data) {
          data.forEach((element) => {
            if (element.Files) {
              element.key = "file" + element.key;
              element.Files.forEach((file) => {
                element.children.push(file);
              });
            }
            return sa(element.children);
          });
        }
        return data;
      };
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ROBOT_PACKAGES,
          Packages: sa(packages),
          state: true,
        });
      }
      dispatch({
        type: ALL_FİLES_TABLE,
        state: false,
      });
      axios
        .get(
          CloudUrl + "/api/file/getallfilestable/" + robotid,
          Config(localStorage.getItem("jwtHeader").toString())
        )
        .then((res) => {
          const files = res.data;
          if (files.Check === false) {
            ErrorMessage(
              files.NormalMessage,
              files.DeveloperMessage,
              files.Type
            );
          } else {
            dispatch({
              type: ALL_FİLES_TABLE,
              Folder: files,
              state: true,
            });
            dispatch({
              type: SPİNNER,
              Spinner: false,
            });
          }
        });
    });
};

export const getAddArosFileToRobot =
  (robotid, fileid, arosid) => (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .get(
        CloudUrl +
          "/api/file/addarosfilerobot/" +
          robotid +
          "/" +
          fileid +
          "/" +
          arosid,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const packages = res.data;

        const sa = (data) => {
          if (data) {
            data.forEach((element) => {
              if (element.Files) {
                element.key = "file" + element.key;
                element.Files.forEach((file) => {
                  element.children.push(file);
                });
              }
              return sa(element.children);
            });
          }
          return data;
        };
        if (packages.Check === false) {
          ErrorMessage(
            packages.NormalMessage,
            packages.DeveloperMessage,
            packages.Type
          );
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        } else {
          dispatch({
            type: ROBOT_PACKAGES,
            Packages: sa(packages),
            state: true,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
          axios
            .get(
              CloudUrl + "/api/file/getallfilesoftherobottable/" + robotid,
              Config(localStorage.getItem("jwtHeader").toString())
            )
            .then((res) => {
              const files = res.data;
              if (files.Check === false) {
                ErrorMessage(
                  files.NormalMessage,
                  files.DeveloperMessage,
                  files.Type
                );
                dispatch({
                  type: SPİNNER,
                  Spinner: false,
                });
              } else {
                dispatch({
                  type: SPİNNER,
                  Spinner: false,
                });
                dispatch({
                  type: ALL_FİLES_TABLE,
                  Folder: files,
                });
              }
            });
        }
      });
  };

export const GetDeleteFileToRobot = (robotid, fileid) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios

    .post(
      CloudUrl + "/api/file/removefiletorobot/" + robotid,
      {
        FileId: fileid,
      },
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Message != null) {
        toast.error(packages.Message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          limit: 1,
        });
      } else {
        const sa = (data) => {
          if (data) {
            data.forEach((element) => {
              if (element.Files) {
                element.key = "file" + element.key;
                element.Files.forEach((file) => {
                  element.children.push(file);
                });
              }
              return sa(element.children);
            });
          }
          return data;
        };
        if (packages.Check === false) {
          ErrorMessage(
            packages.NormalMessage,
            packages.DeveloperMessage,
            packages.Type
          );
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        } else {
          dispatch({
            type: ROBOT_PACKAGES,
            Packages: sa(packages),
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      }

      axios
        .get(
          CloudUrl + "/api/file/getallfilestable/" + robotid,
          Config(localStorage.getItem("jwtHeader").toString())
        )
        .then((res) => {
          const files = res.data;
          if (files.Check === false) {
            ErrorMessage(
              files.NormalMessage,
              files.DeveloperMessage,
              files.Type
            );
          } else {
            dispatch({
              type: ALL_FİLES_TABLE,
              Folder: files,
              state: true,
            });
          }
        });
    });
};

export const GetDeleteFileArosToRobot =
  (robotid, fileid, arosid) => (dispatch) => {
    axios
      .post(
        CloudUrl + "/api/file/RemoveArosFileRobot/" + robotid + "/" + arosid,
        {
          FileId: fileid,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const packages = res.data;
        if (packages.Message != null) {
          toast.error(packages.Message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            limit: 1,
          });
        } else {
          const sa = (data) => {
            if (data) {
              data.forEach((element) => {
                if (element.Files) {
                  element.key = "file" + element.key;
                  element.Files.forEach((file) => {
                    element.children.push(file);
                  });
                }
                return sa(element.children);
              });
            }
            return data;
          };
          if (packages.Check === false) {
            ErrorMessage(
              packages.NormalMessage,
              packages.DeveloperMessage,
              packages.Type
            );
          } else {
            dispatch({
              type: ROBOT_PACKAGES,
              Packages: sa(packages),
            });
          }
        }

        axios
          .get(
            CloudUrl + "/api/file/getallfilesoftherobottable/" + robotid,
            Config(localStorage.getItem("jwtHeader").toString())
          )
          .then((res) => {
            const files = res.data;
            if (files.Check === false) {
              ErrorMessage(
                files.NormalMessage,
                files.DeveloperMessage,
                files.Type
              );
            } else {
              dispatch({
                type: ALL_FİLES_TABLE,
                Folder: files,
                state: true,
              });
            }
          });
      });
  };

//[Admin] Tüm Paketler Tablosunu çeker
export const GetAllPackages = () => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/package/",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: FOLDER_INFO,
          Folder: packages,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};
export const GetAllFiles = () => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/file/",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
      } else {
        dispatch({
          type: ALL_FİLES_TABLE,
          Folder: packages,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetPackageWithRobots = (packetid) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/package/GetPackageWithRobots/" + packetid,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const robots = res.data;
      if (robots.Check === false) {
        ErrorMessage(
          robots.NormalMessage,
          robots.DeveloperMessage,
          robots.Type
        );
      } else {
        dispatch({
          type: PACKAGE_WİTH_ROBOTS,
          Robots: robots,
        });
      }
    });
};

export const GetRobotPrograms = (robotname, useraccessid) => (dispatch) => {
  localStorage.setItem("UserAccessId_Aros", useraccessid);
  console.log(
    ' localStorage.getItem("UserAccessId_Aros").toString(): ',
    localStorage.getItem("UserAccessId_Aros").toString()
  );
  axios
    .get(
      CloudUrl + "/api/robot/getrobotpackages/" + robotname,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const programs = res.data;
      if (programs.Check === false) {
        ErrorMessage(
          programs.NormalMessage,
          programs.DeveloperMessage,
          programs.Type
        );
      } else {
        dispatch({
          type: PROGRAMS_INFO,
          Programs: programs,
        });
      }
    });
};

export const GetProgramsPackages =
  (packageid, robotname) => async (dispatch) => {
    await axios
      .get(
        CloudUrl +
          "/api/package/getchildpackage/" +
          packageid +
          "/" +
          robotname,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const packages = res.data;
        if (packages.Check === false) {
          ErrorMessage(
            packages.NormalMessage,
            packages.DeveloperMessage,
            packages.Type
          );
        } else {
          dispatch({
            type: PACKAGES_INFO,
            Packages: packages,
          });
        }
      });
  };

export const GetFileDescription = (fileid) => async (dispatch) => {
  console.log("fileid: ", fileid);
  await axios
    .get(
      CloudUrl + "/api/file/getfiledescription/" + fileid,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const fildedescription = res.data;
      console.log("fildedescription: ", fildedescription);

      if (fildedescription.Check === false) {
        ErrorMessage(
          fildedescription.NormalMessage,
          fildedescription.DeveloperMessage,
          fildedescription.Type
        );
      } else {
        dispatch({
          type: FİLE_DESCRİPTİON,
          FileDescription: fildedescription,
        });
      }
    });
};
export const GetRobotPackages = (robotname, useraccessid) => (dispatch) => {
  console.log("useraccessid: ", useraccessid);
  localStorage.setItem("UserAccessId_Aros", useraccessid);
  axios
    .get(
      CloudUrl + "/api/robot/getupdatepackages/" + robotname,
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
      } else {
        dispatch({
          type: VERSİONS_INFO,
          Versions: packages,
        });
      }
    });
};

// export const GetRobolizaTEST = () => {
//   return (dispatch) => {
//     axios
//       .get(
//         "https://roboliza.com/api/robots",
//         Config(localStorage.getItem("jwtHeader").toString())
//       )
//       .then((res) => {
//         const user = res.data;
//       });
//   };
// };

export const GetUser = () => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/user",
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const user = res.data;
        if (user.Check === false) {
          ErrorMessage(user.NormalMessage, user.DeveloperMessage, user.Type);
        } else {
          dispatch({
            type: GET_USERS,
            users: user,
          });
        }
      });
  };
};

export const GetRobotsType = () => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/robottype/getrobottypes",
      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const robottype = res.data;
      if (robottype.Check === false) {
        ErrorMessage(
          robottype.NormalMessage,
          robottype.DeveloperMessage,
          robottype.Type
        );
      } else {
        dispatch({
          type: ROBOT_TYPE,
          Type: robottype,
        });
      }
    });
};

export const PostAddRobot = (robottypeid, model, description, colorhexcode) => {
  return (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .post(
        CloudUrl + "/api/robot/addrobot",
        {
          RobotTypeId: robottypeid,
          Model: model,
          Description: description,
          ColorHexCode: colorhexcode,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )
      // .then((res) => {
      //   const packages = res.data;
      //   const sa = (data) => {
      //     if (data) {
      //       data.forEach((element) => {
      //         if (element.Files) {
      //           element.key = "file" + element.key;
      //           element.Files.forEach((file) => {
      //             element.children.push(file);
      //           });
      //         }
      //         return sa(element.children);
      //       });
      //     }
      //     return data;
      //   };
      //   if (packages.Check === false) {
      //     ErrorMessage(
      //       packages.NormalMessage,
      //       packages.DeveloperMessage,
      //       packages.Type
      //     );
      //   } else {
      //     );
      //     dispatch({
      //       type: ROBOT_PACKAGES,
      //       Packages: sa(packages),
      //       message: packages.Message,
      //     });
      //   }
      // });
      .then((res) => {
        const robots = res.data;
        if (robots.Check === false) {
          ErrorMessage(
            robots.NormalMessage,
            robots.DeveloperMessage,
            robots.Type
          );
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        } else {
          dispatch({
            type: ALL_ROBOTS_TABLE,
            Robots: robots,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      });
  };
};

export const PostFilesUpdate =
  (FildeId, Name, Version, Enviroment, VersionComment) => (dispatch) => {
    axios
      .post(
        CloudUrl + "/api/robottype/getrobottypes",
        {
          FildeId: FildeId,
          Name: Name,
          Version: Version,
          Enviroment: Enviroment,
          VersionComment: VersionComment,
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const files = res.data;
        if (files.Check === false) {
          ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
        } else {
          dispatch({
            type: ALL_FİLES_TABLE,
            Folder: files,
          });
        }
      });
  };

export const GetFilesSwicht = (FildeId) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/robottype/getrobottypes" + FildeId,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        dispatch({
          type: ALL_FİLES_TABLE,
          Folder: files,
        });
      }
    });
};

export const GetFilesDelete = (FildeId) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/removefile/" + FildeId,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        dispatch({
          type: ALL_FİLES_TABLE,
          Folder: files,
        });
      }
    });
};

export const GetAllTesterTable = (FildeId) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/testercomment/GetTesterCommentByFileId/" + FildeId,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const tester = res.data;
      if (tester.Check === false) {
        ErrorMessage(
          tester.NormalMessage,
          tester.DeveloperMessage,
          tester.Type
        );
      } else {
        dispatch({
          type: ALL_TESTER_TABLE,
          Tester: tester,
        });
      }
    });
};

export const PostTestComment = (UserId, FileId, Comment) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  console.log("Comment: ", Comment);
  console.log("FileId: ", FileId);
  console.log("UserId: ", UserId);
  axios
    .post(
      CloudUrl + "/api/testercomment/add",

      { UserId: UserId, FileId: FileId, Comment: Comment },

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ALL_FİLES_TABLE,
          Folder: packages,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetAllRobotModelTable = () => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/robottype",

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const model = res.data;
      if (model.Check === false) {
        ErrorMessage(model.NormalMessage, model.DeveloperMessage, model.Type);
      } else {
        dispatch({
          type: ALL_ROBOT_MODEL_TABLE,
          RobotModel: model,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetModelSwichtUpdate = (RobotTypeId) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/robottype/ChangeStatu/" + RobotTypeId,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const model = res.data;
      if (model.Check === false) {
        ErrorMessage(model.NormalMessage, model.DeveloperMessage, model.Type);
      } else {
        dispatch({
          type: ALL_ROBOT_MODEL_TABLE,
          RobotModel: model,
        });
      }
    });
};

export const GetRobotModelAdd = (Name, Description) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });

  axios
    .post(
      CloudUrl + "/api/robottype/Add",
      { Name: Name, Description: Description },

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const model = res.data;
      if (model.Check === false) {
        ErrorMessage(model.NormalMessage, model.DeveloperMessage, model.Type);
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ALL_ROBOT_MODEL_TABLE,
          RobotModel: model,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

export const GetRobotModelUpdate =
  (RobotTypeId, Name, Description) => (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    axios
      .post(
        CloudUrl + "/api/robottype/UpdateRobotType",
        { RobotTypeId: RobotTypeId, Name: Name, Description: Description },

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const model = res.data;
        if (model.Check === false) {
          ErrorMessage(model.NormalMessage, model.DeveloperMessage, model.Type);
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        } else {
          dispatch({
            type: ALL_ROBOT_MODEL_TABLE,
            RobotModel: model,
          });
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        }
      });
  };

export const ArosStart = (newrobotid, robotid, List) => async (dispatch) => {
  axios
    //Devam Eden Aros varmı diye kontrol ediyor.
    .get(
      CloudUrl + "/api/package/ContinueAros",

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const continuearos = res.data;
      if (continuearos === false) {
        dispatch({
          type: MODAL_STATE,
          state: true,
        });
      } else {
        dispatch({
          type: MODAL_STATE,
          state: false,
        });
        dispatch({
          type: SPİNNER,
          Spinner: true,
        });
        axios
          .get(
            CloudUrl + "/api/robot/getallrobots",
            Config(localStorage.getItem("jwtHeader").toString())
          )
          .then((res) => {
            const robots = res.data;

            if (robots.Check === false) {
              ErrorMessage(
                robots.NormalMessage,
                robots.DeveloperMessage,
                robots.Type
              );
            } else {
              dispatch({
                type: ALL_ROBOTS,
                Robots: robots,
              });
            }
            axios
              .post(
                CloudUrl +
                  "/api/package/GetRobotPackagesWithCreateAros/" +
                  robots[0].RobotId,
                {
                  RobotId: robotid,
                  List: List,
                },

                Config(localStorage.getItem("jwtHeader").toString())
              )
              .then((res) => {
                const aros = res.data;

                const sa = (data) => {
                  if (data) {
                    data.forEach((element) => {
                      if (element.Files) {
                        element.key = "file" + element.key;
                        element.disabled = true;
                        element.Files.forEach((file) => {
                          element.children.push(file);
                        });
                      }
                      return sa(element.children);
                    });
                  }
                  return data;
                };
                if (aros.Check === false) {
                  ErrorMessage(
                    aros.NormalMessage,
                    aros.DeveloperMessage,
                    aros.Type
                  );
                  dispatch({
                    type: SPİNNER,
                    Spinner: false,
                  });
                } else {
                  dispatch({
                    type: SPİNNER,
                    Spinner: false,
                  });

                  return dispatch({
                    type: AROS_TESTER,
                    ArosTree: sa(aros.TreeData),
                    ArosChecked: aros.CheckedFiles,
                  });
                }
              });
          });
      }
    });
};

export const DeleteAros = (robotid, List) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .get(
      CloudUrl + "/api/package/ResetArosState",

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        axios
          .post(
            CloudUrl + "/api/package/GetRobotPackagesWithCreateAros/" + robotid,
            {
              RobotId: robotid,
              List: List,
            },

            Config(localStorage.getItem("jwtHeader").toString())
          )
          .then((res) => {
            const aros = res.data;

            const sa = (data) => {
              if (data) {
                data.forEach((element) => {
                  if (element.Files) {
                    element.key = "file" + element.key;
                    element.disabled = true;
                    element.Files.forEach((file) => {
                      element.children.push(file);
                    });
                  }
                  return sa(element.children);
                });
              }
              return data;
            };
            if (aros.Check === false) {
              ErrorMessage(
                aros.NormalMessage,
                aros.DeveloperMessage,
                aros.Type
              );
              dispatch({
                type: SPİNNER,
                Spinner: false,
              });
            } else {
              dispatch({
                type: SPİNNER,
                Spinner: false,
              });

              return dispatch({
                type: AROS_TESTER,
                ArosTree: sa(aros.TreeData),
                ArosChecked: aros.CheckedFiles,
              });
            }
          });
      }
    });
};

//Aros Testerın Dosyaları
export const ArosTesterRobot =
  (newrobotid, robotid, List) => async (dispatch) => {
    dispatch({
      type: SPİNNER,
      Spinner: true,
    });
    await axios
      .post(
        CloudUrl + "/api/package/GetRobotPackagesWithCreateAros/" + newrobotid,
        {
          RobotId: robotid,
          List: List,
        },

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const aros = res.data;

        const sa = (data) => {
          if (data) {
            data.forEach((element) => {
              if (element.Files) {
                element.key = "file" + element.key;
                element.disabled = true;
                element.Files.forEach((file) => {
                  element.children.push(file);
                });
              }
              return sa(element.children);
            });
          }
          return data;
        };
        if (aros.Check === false) {
          ErrorMessage(aros.NormalMessage, aros.DeveloperMessage, aros.Type);
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
        } else {
          dispatch({
            type: SPİNNER,
            Spinner: false,
          });
          return dispatch({
            type: AROS_TESTER,
            ArosTree: sa(aros.TreeData),
            ArosChecked: aros.CheckedFiles,
          });
        }
      });
  };

//İndirme Logları tutan yer
export const GetDowloandFilesTable = (UserId) => (dispatch) => {
  dispatch({
    type: ALL_DOWLOANDFİLES,
    loading: true,
  });
  axios
    .get(
      CloudUrl + "/api/downloadlog/GetUsersDownloadLog/" + UserId,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        dispatch({
          type: ALL_DOWLOANDFİLES,
          Files: files,
          loading: false,
        });
      }
    });
};

//Yükleme ogları tutan yer
export const GetUploadFilesTable = (UserId) => (dispatch) => {
  axios
    .get(
      CloudUrl + "/api/file/UserUploadedFiles/" + UserId,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const files = res.data;
      if (files.Check === false) {
        ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
      } else {
        dispatch({
          type: ALL_DOWLOANDFİLES,
          Files: files,
        });
      }
    });
};

export const PostCreateAros =
  (UserId, Description, MajorOrMinor, Update, Fixed) => (dispatch) => {
    axios
      .post(
        CloudUrl + "/api/updatepackage/createaros",
        {
          MajorOrMinor: MajorOrMinor,
          Update: Update,
          UserId: UserId,
          Fixed: Fixed,
          Description: Description,
        },

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const files = res.data;
        if (files.Check === false) {
          ErrorMessage(files.NormalMessage, files.DeveloperMessage, files.Type);
        } else {
        }
      });
  };

export const GetCheckedPost = (checkedfile, robotid) => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });
  axios
    .post(
      CloudUrl +
        "/api/package/SelectSingleFilePackage/" +
        checkedfile +
        "/" +
        robotid,

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const aros = res.data;

      const sa = (data) => {
        if (data) {
          data.forEach((element) => {
            if (element.Files) {
              element.key = "file" + element.key;
              element.disabled = true;
              element.Files.forEach((file) => {
                element.children.push(file);
              });
            }
            return sa(element.children);
          });
        }
        return data;
      };
      if (aros.Check === false) {
        ErrorMessage(aros.NormalMessage, aros.DeveloperMessage, aros.Type);
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      } else {
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
        return dispatch({
          type: AROS_TESTER,
          ArosTree: sa(aros.TreeData),
          ArosChecked: aros.CheckedFiles,
        });
      }
    });
};

//[ADMİN]Tüm Paketleri Tablo için çeken fonksiyon
export const GetAllPackagesTable = () => (dispatch) => {
  dispatch({
    type: SPİNNER,
    Spinner: true,
  });

  axios
    .get(
      CloudUrl + "/api/package/getallactive",

      Config(localStorage.getItem("jwtHeader").toString())
    )
    .then((res) => {
      const packages = res.data;
      if (packages.Check === false) {
        ErrorMessage(
          packages.NormalMessage,
          packages.DeveloperMessage,
          packages.Type
        );
        dispatch({
          type: ALL_DOWLOANDFİLES,
          Spinner: false,
        });
      } else {
        dispatch({
          type: ALL_PACKAGES_TABLE,
          Packages: packages,
        });
        dispatch({
          type: SPİNNER,
          Spinner: false,
        });
      }
    });
};

//Store Programları çekmeye yarayan APİ
export const GetAllStorePrograms = (productrobot) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/GetProducedRobotsFilesById/" + productrobot,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;
        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: ALL_STORE_TABLE,
            Programs: progmram,
          });
        }
      });
  };
};

//Store Programları eklemeye  yarayan APİ
export const GetAddStorePrograms = (productrobot, fileid) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl +
          "/api/robot/AddFileToProducedRobots/" +
          productrobot +
          "/" +
          fileid,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;
        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: ALL_STORE_TABLE,
            Programs: progmram,
          });
          axios
            .get(
              CloudUrl +
                "/api/robot/GetExceptProducedRobotsFilesById/" +
                productrobot,
              Config(localStorage.getItem("jwtHeader").toString())
            )
            .then((res) => {
              const progmram = res.data;
              if (progmram.Check === false) {
                ErrorMessage(
                  progmram.NormalMessage,
                  progmram.DeveloperMessage,
                  progmram.Type
                );
              } else {
                dispatch({
                  type: ALL_STORE_PROGRAMS_TABLE,
                  Programs: progmram,
                });
              }
            });
        }
      });
  };
};

//Store Programları silmeye  yarayan APİ
export const GetDeleteStorePrograms = (productrobot, fileid) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl +
          "/api/robot/RemoveFileToProducedRobots/" +
          productrobot +
          "/" +
          fileid,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;
        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: ALL_STORE_TABLE,
            Programs: progmram,
          });
          axios
            .get(
              CloudUrl +
                "/api/robot/GetExceptProducedRobotsFilesById/" +
                productrobot,
              Config(localStorage.getItem("jwtHeader").toString())
            )
            .then((res) => {
              const progmram = res.data;
              if (progmram.Check === false) {
                ErrorMessage(
                  progmram.NormalMessage,
                  progmram.DeveloperMessage,
                  progmram.Type
                );
              } else {
                dispatch({
                  type: ALL_STORE_PROGRAMS_TABLE,
                  Programs: progmram,
                });
              }
            });
        }
      });
  };
};

//Store Programları silmeye  yarayan APİ
export const GetAllTableStorePrograms = (productrobot) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl +
          "/api/robot/GetExceptProducedRobotsFilesById/" +
          productrobot,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;
        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: ALL_STORE_PROGRAMS_TABLE,
            Programs: progmram,
          });
        }
      });
  };
};

//Showcasede Gösterilecek marketing itemlerini alır
export const GetAllMarketingFiles = () => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/GetAllMarkettingpackages",
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;
        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: ALL_SHOW_PROGRAMS,
            Programs: progmram,
          });
        }
      });
  };
};

//Uygulamların Kodlarını almamızı sağlayan api
export const GetFilesCode = (packageid) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/file/PackagesLastFileCode/" + packageid,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;
        console.log("progmram: ", progmram);

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: PACKAGES_CODE,
            code: progmram,
          });
        }
      });
  };
};

//Uygulamların Marketting İçeriğini almamızı sağlayan api
export const GetMarkettingFiles = (robotid) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/GetProducedRobotsFiles/" + robotid,
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          dispatch({
            type: ALL_MARKETTİNG,
            Files: progmram,
          });
        }
      });
  };
};

export const PostResetPassword = (
  UserId,
  OldPassword,
  NewPassword,
  NewPasswordRepeat
) => {
  return (dispatch) => {
    axios
      .post(
        CloudUrl + "/api/user/changeuserpassword",
        {
          UserId: UserId,
          OldPassword: md5(OldPassword),
          NewPassword: md5(NewPassword),
          NewPasswordRepeat: md5(NewPasswordRepeat),
        },
        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          console.log(res.data);
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        }
      });
  };
};

export const GetFilesDescriptionFile = (FileId) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/file/fileupdateinfo/" + FileId,

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          console.log(res.data);
          dispatch({
            type: FİLE_DESCRİPTİON_DATA,
            files: progmram,
          });
        }
      });
  };
};

export const UploadFileDescription =
  (PackageId, Version, EnvironmentId, file) => (dispatch) => {
    axios({
      url: CloudUrl + "/api/file/UpdateFile",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtHeader").toString(),
      },
      onUploadProgress: (progressEvent) =>
        dispatch({
          type: UPDATES_INFO,
          updates_time: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          ),
          state: true,
        }), //your url
      method: "POST",
      data: file,
    }).then((res) => {
      const updates = res.data;
      if (updates.Check === false) {
        ErrorMessage(
          updates.NormalMessage,
          updates.DeveloperMessage,
          updates.Type
        );
        dispatch({
          type: UPDATES_INFO,
          state: false,
        });
      } else {
        dispatch({
          type: UPDATES_INFO,
          message: updates.Message,
          updates_time: updates.Message,
        });
      }
    });
  };

export const GetRobotsArosVersions = (RobotId) => {
  console.log("RobotId: ", RobotId);

  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/RobotArosById/" + RobotId,

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          console.log(res.data);
          dispatch({
            type: ROBOT_AROS_ALL,
            aros: progmram,
          });
        }
      });
  };
};

export const GetRobotRunTimeInfo = (RobotId) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/robotruntimeinfo/" + RobotId,

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          console.log(res.data);
          dispatch({
            type: ROBOT_RUN_TIME,
            info: progmram,
          });
        }
      });
  };
};

export const GetCheckVersion = (producedrobotid) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/checkupdate/" + producedrobotid,

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          console.log("[0]Data:", res.data);
          dispatch({
            type: UPDATE_STATE,
            update: progmram,
          });
        }
      });
  };
};

export const GetAddUpdateNotifier = (producedrobotid) => {
  return (dispatch) => {
    axios
      .get(
        CloudUrl + "/api/robot/AddUpdateNotifier/" + producedrobotid,

        Config(localStorage.getItem("jwtHeader").toString())
      )
      .then((res) => {
        const progmram = res.data;

        if (progmram.Check === false) {
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        } else {
          console.log("[0]Data:", res.data);
          ErrorMessage(
            progmram.NormalMessage,
            progmram.DeveloperMessage,
            progmram.Type
          );
        }
      });
  };
};

import { ALL_FİLES_TABLE } from "../actions/actions";

const INITIAL_STATE = {
  Files: [],
  ArosId: null,
  state: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_FİLES_TABLE: {
      return {
        ...state,
        Folder: action.Folder,
        state: action.state,
        ArosId: action.ArosId,
      };
    }
    default:
      return state;
  }
};

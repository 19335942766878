import { toast } from "react-toastify";
import React from "react";
export default function ErrorMessage(normalmessage, developermessage, type) {
  toast(
    <div>
      <h6 style={{}}>{normalmessage}</h6>

      <p style={{ fontSize: 11, marginTop: "3%" }}>{developermessage}</p>
    </div>,
    {
      type: type,
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      limit: 1,
    }
  );
}

import { Avatar, Drawer, Dropdown, List, Menu, Spin, Tooltip } from "antd";
import React, { useState } from "react";
import "react-awesome-slider/dist/styles.css";
import { Scrollbars } from "react-custom-scrollbars";
import { AiOutlineMenuFold } from "react-icons/ai";
import { BsCloudUploadFill } from "react-icons/bs";
import { GoDesktopDownload } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import {
  GetDowloandFilesTable,
  GetUploadFilesTable,
} from "../../redux/actions/taskActions";
import SideBar from "../SideBar/SideBar";
export default function Header() {
  const dispatch = useDispatch();
  const AllDownloadFiles = useSelector((state) => state.AllDownloadFiles);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const User = useSelector((state) => state.User);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [Driver, setDriver] = useState(false);
  const data = AllDownloadFiles.Files;
  //Dowloand Dropdonına istek atar ve indirme verileri çeker
  function DropdownOpen(e) {
    e.preventDefault();
    dispatch(GetDowloandFilesTable(User.User.UserId));
  }
  //Upload  Dropdonına istek atar ve indirme verileri çeker
  function UploadDropDown(e) {
    e.preventDefault();
    dispatch(GetUploadFilesTable(User.User.UserId));
  }

  //Driverin açılma kapanma fonsiyonlarıo
  const showDrawer = () => {
    setDriver(true);
  };

  const onClose = () => {
    setDriver(false);
  };

  //Upload  Dropdown menüsü
  const menu = (
    <Menu style={{ height: 400, width: 300, backgroundColor: "#2B2B2B" }}>
      <Scrollbars style={{ width: "100%", height: 400 }}>
        <List
          style={{ margin: 20, color: "white" }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              {item.ImageName === undefined &&
              item.ImageName === null &&
              item.ImageName === "" &&
              item.ImageName === "null" ? (
                <List.Item.Meta
                  key={item.FileId}
                  title={<a style={{ color: "white" }}>{item.FileName}</a>}
                  description={
                    <Tooltip title={item.RobotName}>
                      <a style={{ color: "white" }}>{item.DownloadDate}</a>
                    </Tooltip>
                  }
                />
              ) : (
                <List.Item.Meta
                  key={item.FileId}
                  avatar={
                    <Avatar
                      src={global.config.imagepath.icons + item.ImageName}
                    />
                  }
                  title={<a style={{ color: "white" }}>{item.FileName}</a>}
                  description={
                    <Tooltip title={item.RobotName}>
                      <a style={{ color: "white" }}>{item.DownloadDate}</a>
                    </Tooltip>
                  }
                />
              )}
            </List.Item>
          )}
        />
      </Scrollbars>
    </Menu>
  );
  //Dowloand  Dropdonına menüsü
  const dropdownmenü = (
    <Menu style={{ height: 400, width: 300, backgroundColor: "#2B2B2B" }}>
      <Scrollbars style={{ width: "100%", height: 400 }}>
        <List
          style={{ margin: 20, color: "white" }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              {item.ImageName === undefined && item.ImageName === null ? (
                <List.Item.Meta
                  key={item.FileId}
                  title={<a style={{ color: "white" }}>{item.Name}</a>}
                  description={
                    <Tooltip title={item.RobotName}>
                      <a style={{ color: "white" }}>{item.DownloadDate}</a>
                    </Tooltip>
                  }
                />
              ) : (
                <List.Item.Meta
                  key={item.FileId}
                  avatar={
                    <Avatar
                      src={global.config.imagepath.icons + item.ImageName}
                    />
                  }
                  title={<a style={{ color: "white" }}>{item.Name}</a>}
                  description={
                    <Tooltip title={item.RobotName}>
                      <a style={{ color: "white" }}>{item.UploadDate}</a>
                    </Tooltip>
                  }
                />
              )}
            </List.Item>
          )}
        />
      </Scrollbars>
    </Menu>
  );

  const styles = {
    HeaderMain: {
      boxShadow: "8px 5px 11px 2px rgba(0,0,0,0.45)",
      backgroundColor: "#1D1D1D",
      color: "grey",
      height: "6%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      alignSelf: "flex-end",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    Logo: {
      padding: "17px",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "1px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      height: "5%",
    },
    Dropdown: {
      flex: 1,
      flexDirection: "row",
      display: "flex",
      padding: "1%",
      flexDirection: "column",
      alignItems: "stretch",
      alignSelf: "flex-end",
      alignItems: "flex-end",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: "3%",
    },
  };

  return (
    <div style={styles.HeaderMain}>
      {isTabletOrMobile && (
        <div
          className="site-drawer-render-in-current-wrapper"
          style={{
            alignSelf: "flex-end",
            alignItems: "flex-end",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <AiOutlineMenuFold
            size={29}
            style={{ marginLeft: "%30" }}
            onClick={showDrawer}
          ></AiOutlineMenuFold>

          <Drawer
            onClose={onClose}
            visible={Driver}
            drawerStyle={{
              width: "100%",
              height: "100%",
              backgroundColor: "#1D1D1D",
            }}
          >
            <div style={{ position: "absolute", marginRight: "19%" }}>
              <SideBar></SideBar>
            </div>
          </Drawer>
        </div>
      )}

      <div style={{ flex: 1 }}></div>
      <div style={{ flex: 1 }}>
        {isTabletOrMobile && (
          <div style={styles.Logo}>
            
            Roboliza
          </div>
        )}
      </div>
      <div style={{ flex: 3 }}></div>
      <div style={styles.Dropdown}>
        <div style={{ marginRight: "5%", marginTop: "9%" }}>
          {User.role === "Customer" && User.role === "Marketting" ? (
            " "
          ) : (
            <Dropdown
              overlay={dropdownmenü}
              trigger={["click"]}
              onClick={(e) => UploadDropDown(e)}
              arrow
            >
              <BsCloudUploadFill
                style={{ fontSize: 22, color: "white" }}
              ></BsCloudUploadFill>
            </Dropdown>
          )}
        </div>
        <div style={{ height: "1%" }}> </div>
        <div style={{ marginRight: "80%", marginTop: "39%" }}>
          {User.role === "Customer" && User.role === "Marketting" ? (
            " "
          ) : (
            <div>
              {isDesktopOrLaptop && (
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  onClick={(e) => DropdownOpen(e)}
                  arrow
                >
                  <GoDesktopDownload
                    style={{ color: "white", fontSize: 22 }}
                  ></GoDesktopDownload>
                </Dropdown>
              )}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

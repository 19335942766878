export default function OperatinSystemServices() {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "Mac";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux";
  }
  if (window.navigator.appVersion.indexOf("Android") !== -1) {
    operatingSystem = "Android";
  }
  if (window.navigator.appVersion.indexOf("IOS") !== -1) {
    operatingSystem = "IOS";
  }

  return operatingSystem;
}

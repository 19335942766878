import { USER_INFO } from "./actions";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ErrorMessage from "../../services/MessageServices";

import { CloudUrl } from "../../services/GlobalVariables";

var md5 = require("md5");
const axios = require("axios");

//Çıkış Yapma İsteği
export const LogOut = () => async (dispatch) => {
  localStorage.removeItem("jwtHeader");

  dispatch({
    type: USER_INFO,
    state: false,
  });
};

//Giriş Yapma İsteğimiz
export const LoginControl = (UserName, Password) => async (dispatch) => {
  await axios

    .post(CloudUrl + `/api/user/SignIn`, {
      UserName: UserName,
      Password: md5(Password),
    })
    .then((res) => {
      const token = res.data;
      if (token.Check === undefined) {
        //User Tokenimizi reduxa kayıt ediyoruz.
        //
        var decoded = jwt_decode(token);
        var userid = decoded.UserId;

        //Config Dosyamızı local store atıyoruz.
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        localStorage.setItem("jwtHeader", token);

        axios
          .get(CloudUrl + `/api/user/getUserInformations/` + userid, config)
          .then((res) => {
            const user = res.data;
            if (user.Check == false) {
              ErrorMessage(
                user.NormalMessage,
                user.DeveloperMessage,
                user.Type
              );
            } else {
              dispatch({
                type: USER_INFO,
                User: user,
                token: token,
                role: decoded.Roles,
                state: true,
              });
            }
          })
          .catch((error) => {});
      } else {
        dispatch({
          type: USER_INFO,
          users: null,
          state: false,
        });
        toast.error("Şifre veya Kullanıcı Adı Hatalı", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          limit: 1,
        });
      }
    })
    .catch((error) => {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        limit: 1,
      });
    });
};

module.exports = global.config = {
  // imagepath: {
  //   images: " http://image.ahmetuysal/images/",
  //   icons: " http://image.ahmetuysal/icons/",
  // },

  imagepath: {
    images: " https://image.roboliza.com/images/",
    icons: " https://image.roboliza.com/icons/",
  },
};

import {
  faAtom,
  faBars,
  faCode,
  faCodeBranch,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "react-awesome-slider/dist/styles.css";
// import background from "../assets/images/arkaPlan.jpg";
import { FaSignInAlt } from "react-icons/fa";
import { MdDeveloperMode } from "react-icons/md";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogOut } from "../../../redux/actions/LoginAction";
import {
  GetAllFiles,
  GetAllPackages,
  GetRobotPrograms,
  SidebarState,
} from "../../../redux/actions/taskActions";
export default function DeveloperSideBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [SideBar, SetSideBar] = useState(false);
  const [Profile, SetProfile] = useState("visible");
  const SidebarToogleUser = useSelector((state) => state.SidebarToogle);

  //SİdeBaro Kontrol eder
  useEffect(() => {
    if (SideBar === true) {
      SetProfile("hidden");
    } else {
      SetProfile("visible");
    }
  }, [SideBar]);
  const User = useSelector((state) => state.User);

  //Robota bağlı programları çeker ve sayfa yönlendirmesi yapar
  const GetPrograms_ = (robotname, UserAccessId) => {
    dispatch(GetRobotPrograms(robotname, UserAccessId));
    history.push("/programs");
  };
  //Robota bağlı Özel Yazılımları çeker ve sayfa yönlendirmesi yapar
  const GetAllFilesButton = () => {
    dispatch(GetAllPackages());
    dispatch(GetAllFiles());
    history.push("/adminfiles");
  };
  //Robota bağlı Paketleri çeker ve sayfa yönlendirmesi yapar
  const GetAllPackageClick = () => {
    dispatch(GetAllPackages());
    history.push("/uploadsoftware");
  };
  //Çıkış yapma fonksiyonu
  const Exit = () => {
    dispatch(LogOut());
    history.push("/login");
  };

  const styles = {
    SideBarHeader: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    SideBarHeaderIcon: {
      fontSize: 30,
      direction: "flex",
      justifyItems: "flex-end",
      justifyContent: "flex-end",
      marginRight: 10,
      marginTop: 10,
    },
    SideBarBottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      visibility: Profile,
    },
    SideBarImage: {
      height: 90,
      width: 90,
      opacity: 0.5,
      bottom: 0,
      right: 0,
      borderRadius: 120,
      borderWidth: 2,
      borderColor: "white",
    },
    SideBarMail: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    SideBarPhone: {
      marginLeft: 20,
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    SideBarLogo: {
      padding: "17px",
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "1px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      height: "5%",
    },
  };
  return (
    <div
      style={{ height: "100%", boxShadow: "8px 5px 11px 2px rgba(0,0,0,0.45)" }}
    >
      <ProSidebar
        toggled={true}
        collapsed={SidebarToogleUser.state}
        color="red"
      >
        <div className=" sidebar-item sidebar-menu"></div>
        <SidebarHeader onClick={() => history.push("/home")}>
          <div style={styles.SideBarLogo}>Roboliza</div>
        </SidebarHeader>
        <SidebarHeader style={{ backgroundColor: "#2b2b2b" }}>
          <div style={styles.SideBarHeader}>
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => dispatch(SidebarState(!SidebarToogleUser.state))}
              style={styles.SideBarHeaderIcon}
            />
          </div>
          {SidebarToogleUser.state === false ? (
            <div style={styles.SideBarBottom}>
              <Avatar
                size={100}
                icon={
                  <MdDeveloperMode
                    style={{
                      color: "#1d1d1d",
                      marginLeft: 23,
                      marginTop: 23,
                    }}
                  />
                }
              />
              <div>
                <p
                  style={{ fontWeight: "bold", fontSize: 12, marginTop: "3%" }}
                >
                  {User.User.Name} {User.User.Surname}
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: "3%",
                  fontSize: 12,
                  marginBottom: "3%",
                }}
              >
                {" "}
              </div>
            </div>
          ) : (
            ""
          )}
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle" title={"Profil"}>
            <SubMenu
              title="Robotlarım"
              icon={
                <Badge
                  count={User.User.UserAccesses.length}
                  size="small"
                  color="grey"
                >
                  <FontAwesomeIcon icon={faRobot} />
                </Badge>
              }
            >
              {User.User.UserAccesses.map((Robot) => (
                <MenuItem
                  key={Robot.ProducedRobots.Robot.Model}
                  onClick={() =>
                    GetPrograms_(
                      Robot.ProducedRobots.Robot.Model,
                      Robot.UserAccessId
                    )
                  }
                  icon={<FontAwesomeIcon icon={faRobot} />}
                >
                  {Robot.RobotName}
                </MenuItem>
              ))}
            </SubMenu>
          </Menu>

          <Menu iconShape="square">
            <SubMenu
              title="Robot İşlemleri"
              icon={<FontAwesomeIcon icon={faCode} />}
            >
              <MenuItem
                onClick={() => GetAllFilesButton()}
                icon={<FontAwesomeIcon icon={faCodeBranch} />}
              >
                Özel Yazılımlar
              </MenuItem>
              <MenuItem
                onClick={() => GetAllPackageClick()}
                icon={<FontAwesomeIcon icon={faAtom} />}
              >
                Yazılım Yükle
              </MenuItem>
            </SubMenu>
          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: "center", alignItems: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "flex",
                marginLeft: "28%",
              }}
              visibility={Profile}
              onClick={() => Exit()}
            >
              <FaSignInAlt
                style={{ fontSize: 19, marginRight: 3 }}
                onClick={() => Exit()}
              />
              {SidebarToogleUser.state === false ? " Çıkış Yap" : ""}
            </span>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
}

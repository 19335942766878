import { VERSİONS_INFO, UPDATE_STATE } from "../actions/actions";

const INITIAL_STATE = {
  Versions: [],
  UpdateState: [],
  state: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERSİONS_INFO: {
      return {
        ...state,
        Versions: action.Versions,
      };
    }
    case UPDATE_STATE: {
      return {
        ...state,
        UpdateState: action.update,
      };
    }
    default:
      return state;
  }
};

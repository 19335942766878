export const GET_USERS = "GET_USERS";
export const USER_INFO = "USER_INFO";
export const ROBOT_AROS_ALL = "ROBOT_AROS_ALL";

export const VERSİONS_INFO = "VERSİONS_INFO";
export const UPDATES_INFO = "UPDATES_INFO";
export const ALL_USERS = "ALL_USERS";
export const FOLDER_INFO = "FOLDER_INFO";
export const PACKAGE_WİTH_ROBOTS = "PACKAGE_WİTH_ROBOTS";
export const FİLE_DESCRİPTİON = "FİLE_DESCRİPTİON";
export const PROGRAMS_INFO = "PROGRAMS_INFO";
export const PACKAGES_INFO = "PACKAGES_INFO";
export const AROS_PROGRAMS = "AROS_PROGRAMS";
export const PROGRAMS_PACKAGES = "PROGRAMS_PACKAGES";
export const ALL_ROBOTS = "ALL_ROBOTS";
export const ROBOT_PACKAGES = "ROBOT_PACKAGES";
export const ALL_ROBOTS_TABLE = "ALL_ROBOTS_TABLE";
export const ALL_FİLES_TABLE = "ALL_FİLES_TABLE";
export const GLOBAL_STATE = "GLOBAL_STATE";
export const GET_ALL_PROGRAMS = "GET_ALL_PROGRAMS";
export const ROBOT_TYPE = "ROBOT_TYPE";
export const AROS_VERSİON = "AROS_VERSİON";
export const USER_ROLE = "USER_ROLE";
export const ALL_PRODUCE_ROBOT = "ALL_PRODUCE_ROBOT";
export const USER_SELECT = "USER_SELECT";
export const ALL_TESTER_TABLE = "ALL_TESTER_TABLE";
export const ALL_ROBOT_MODEL_TABLE = "ALL_ROBOT_MODEL_TABLE";
export const AROS_TESTER = "AROS_TESTER";
export const ALL_DOWLOANDFİLES = "ALL_DOWLOANDFİLES";
export const SPİNNER = "SPİNNER";
export const ALL_PACKAGES_TABLE = "ALL_PACKAGES_TABLE";
export const DOWNLOAD_PROCESS = "DOWNLOAD_PROCESS";
export const MODAL_STATE = "MODAL_STATE";
export const SİDEBAR_STATE = "SİDEBAR_STATE";
export const ALL_STORE_TABLE = "ALL_STORE_TABLE";
export const ALL_STORE_PROGRAMS_TABLE = "ALL_STORE_PROGRAMS_TABLE";
export const ALL_SHOW_PROGRAMS = "ALL_SHOW_PROGRAMS";
export const PACKAGES_CODE = "PACKAGES_CODE";
export const ALL_MARKETTİNG = "ALL_MARKETTİNG";

export const UPDATE_STATE = "UPDATE_STATE";

export const ROBOT_RUN_TIME = "ROBOT_RUN_TIME";

export const FİLE_DESCRİPTİON_DATA = "FİLE_DESCRİPTİON_DATA";

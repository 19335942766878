import React from "react";
import { useSelector } from "react-redux";

import SideBar from "../components/SideBar/SideBar";
import Navigation from "../navigations/navigation";
import Header from "../components/Headers/Header";
import CustomerHeader from "../components/Headers/CustomerHeader";
export default function Layout() {
  const User = useSelector((state) => state.User);
  return (
    <>
      <div style={styles.Main}>
        {User.role === "Admin" ||
        User.role === "SuperAdmin" ||
        User.role === "Marketting" ||
        User.role === "Developer" ||
        User.role === "Tester" ? (
          <SideBar> </SideBar>
        ) : (
          ""
        )}

        <div style={styles.Description}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ||
          User.role === "Developer" ||
          User.role === "Tester" ? (
            <Header></Header>
          ) : (
            ""
          )}

          {User.role === "Customer" ? <CustomerHeader></CustomerHeader> : ""}
          <Navigation />
        </div>
      </div>
    </>
  );
}

const styles = {
  Main: {
    overflow: "hidden",
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
  },
  Description: {
    width: "100%",
    height: "100%",
  },
};

import React, { lazy, Suspense } from "react";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "../custom.css";
import "../components/Packages/Package.css";
import Loading from "../pages/Loading/Loading";
const AdminRobotDescription = lazy(() =>
  import("../pages/Admin/AdminRobotDescription/AdminRobotDescription")
);
const Trailer = lazy(() => import("../pages/Trailer/Trailer"));
const Home = lazy(() => import("../pages/Home/Home"));
const AdminAros = lazy(() => import("../pages/Admin/AdminAros/AdminAros"));
const AdminFiles = lazy(() => import("../pages/Admin/AdminFiles/AdminFiles"));
const AdminPackages = lazy(() =>
  import("../pages/Admin/AdminPackages/AdminPackages")
);
const AdminProduceRobots = lazy(() =>
  import("../pages/Admin/AdminProduceRobots/AdminProduceRobots")
);
const AdminPrograms = lazy(() =>
  import("../pages/Admin/AdminPrograms/AdminPrograms")
);
const AdminRobotModel = lazy(() =>
  import("../pages/Admin/AdminRobotModel/AdminRobotModel")
);
const AdminRobots = lazy(() =>
  import("../pages/Admin/AdminRobots/AdminRobots")
);
const AdminTestComment = lazy(() =>
  import("../pages/Admin/AdminTestComment/TestComment")
);
const AdminUser = lazy(() => import("../pages/Admin/AdminUsers/AdminUsers"));
const CreateAros = lazy(() => import("../pages/CreateAros/CreateAros"));
const Login = lazy(() => import("../pages/Login/Login"));
const Programs = lazy(() => import("../pages/Programs/Programs"));
const ErrorScreen = lazy(() => import("../pages/404/ErrorScreen"));
const UploadSoftware = lazy(() => import("../pages/Upload/UploadSoftware"));
const AdminStore = lazy(() => import("../pages/Admin/AdminStore/AdminStore"));
const VersionsUser = lazy(() => import("../pages/UserVersion/Version"));
const Versions = lazy(() => import("../pages/Versions/Versions"));
const Profil = lazy(() => import("../pages/Profil/Profil"));
const EditSoftware = lazy(() => import("../pages/Upload/EditSoftware"));
const Navigation = () => {
  const User = useSelector((state) => state.User);
  return (
    <Switch>
      <Route path="/editsoftware">
        <Suspense fallback={<Loading></Loading>}>
          <EditSoftware />
        </Suspense>
      </Route>
      <Route path="/profil">
        <Suspense fallback={<Loading></Loading>}>
          <Profil />
        </Suspense>
      </Route>
      <Route path="/adminrobotdescription">
        <Suspense fallback={<Loading></Loading>}>
          <AdminRobotDescription />
        </Suspense>
      </Route>
      <Route path="/trailer">
        <Suspense fallback={<Loading></Loading>}>
          <Trailer />
        </Suspense>
      </Route>
      <Route path="/505">
        <Suspense fallback={<Loading></Loading>}>
          <ErrorScreen />
        </Suspense>
      </Route>
      <Route path="/adminstore">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ? (
            <AdminStore />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/adminrobotmodel">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ? (
            <AdminRobotModel />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>

      <Route path="/admintestcomment">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" || User.role === "SuperAdmin" ? (
            <AdminTestComment />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/adminfiles">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Developer" ||
          User.role === "Tester" ? (
            <AdminFiles />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/adminproducerobots">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ? (
            <AdminProduceRobots />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/adminaros">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" || User.role === "SuperAdmin" ? (
            <AdminAros />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/adminprograms">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" || User.role === "SuperAdmin" ? (
            <AdminPrograms />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/adminusers">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ? (
            <AdminUser />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>

      <Route path="/adminpackages">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ? (
            <AdminPackages />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>

      <Route path="/adminrobots">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Marketting" ? (
            <AdminRobots />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>

      <Route path="/uploadsoftware">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Admin" ||
          User.role === "SuperAdmin" ||
          User.role === "Developer" ||
          User.role === "Tester" ? (
            <UploadSoftware />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/programs">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Developer" || User.role === "Tester" ? (
            <Programs />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/home">
        <Suspense fallback={<Loading></Loading>}>
          <Home />
        </Suspense>
      </Route>
      <Route path="/versions">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Developer" || User.role === "Tester" ? (
            <Versions />
          ) : (
            <ErrorScreen />
          )}
        </Suspense>
      </Route>
      <Route path="/userversions">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Customer" ? <VersionsUser /> : <ErrorScreen />}
        </Suspense>
      </Route>

      <Route path="/createaros">
        <Suspense fallback={<Loading></Loading>}>
          {User.role === "Tester" ? <CreateAros /> : <ErrorScreen />}
        </Suspense>
      </Route>
      <Route path="/">
        <Suspense fallback={<Loading></Loading>}>
          <Login />
        </Suspense>
      </Route>
    </Switch>
  );
};

export default Navigation;

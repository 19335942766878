import { ROBOT_RUN_TIME } from "../actions/actions";

const INITIAL_STATE = {
  Info: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROBOT_RUN_TIME: {
      return {
        ...state,
        Info: action.info,
      };
    }
    default:
      return state;
  }
};

import { Drawer, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import "react-awesome-slider/dist/styles.css";
import { AiOutlineLogout, AiOutlineMenuFold } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { GlobalRobotName } from "../../redux/actions/GlobalAction";
import {
  GetCheckVersion,
  GetMarkettingFiles,
  GetRobotPackages,
  LogOut,
} from "../../redux/actions/taskActions";
export default function Header() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const dispatch = useDispatch();
  const history = useHistory();
  const User = useSelector((state) => state.User);
  const [Driver, setDriver] = useState(false);

  //Markettin Dosylarına ve aros sürümü ,güncellemeler,geliştiren kullanıcıları gösterir.
  const GetPackages_ = (robotname, useraccessid, producterobotid) => {
    dispatch(GetMarkettingFiles(producterobotid));
    console.log("producterobotid: ", producterobotid);
    dispatch(GetRobotPackages(robotname, useraccessid));
    dispatch(GlobalRobotName(producterobotid));
    dispatch(GetCheckVersion(producterobotid));
    history.push("/userversions");
  };
  //Driverin açılma kapanma fonsiyonlarıo
  const showDrawer = () => {
    setDriver(true);
  };
  const onClose = () => {
    setDriver(false);
  };

  //Çıkış yapma fonksiyonu
  const Exit = () => {
    dispatch(LogOut());
    history.push("/login");
  };

  //Upload  Dropdown menüsü
  const menu = (
    <Menu style={{ backgroundColor: "#131417" }}>
      {User.User.UserAccesses.map((Robot) => (
        <Menu.Item key="0" style={{ backgroundColor: "#131417" }}>
          <span
            style={{ color: "white" }}
            onClick={() =>
              GetPackages_(
                Robot.ProducedRobots.Robot.Model,
                Robot.UserAccessId,
                Robot.ProducedRobotsId
              )
            }
          >
            {Robot.RobotName}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div
      style={{
        boxShadow: "8px 5px 11px 2px rgba(0,0,0,0.8)",
        backgroundColor: "#1D1D1D",
        color: "grey",
        height: "6%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {isTabletOrMobile && (
        <div style={{ flexDirection: "row", display: "flex", width: "100%" }}>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <AiOutlineMenuFold
              size={25}
              style={{
                position: "absolute",
                right: "1%",
                top: "1%",
              }}
              onClick={showDrawer}
            ></AiOutlineMenuFold>
          </div>
          <div
            style={{ position: "absolute", left: "17%", top: "3%" }}
            className="headertitle"
            onClick={() => history.push("/home")}
          ></div>
          <Drawer
            onClose={onClose}
            visible={Driver}
            drawerStyle={{
              width: "100%",
              height: "100%",
              backgroundColor: "#1D1D1D",
            }}
            footer={
              <span
                style={{
                  alignItems: "center",
                  justifyItems: "center",
                  flexDirection: "row",
                  display: "flex",
                  textAlign: "center",
                }}
                onClick={() => Exit()}
              >
                ÇIKIŞ YAP
              </span>
            }
          >
            <div
              style={{
                position: "absolute",
                marginRight: "19%",
                backgroundColor: "#1D1D1D",
                flexDirection: "column",
                display: "flex  ",
              }}
            >
              <span
                style={{ marginTop: "4%", marginLeft: "12%" }}
                className="headertitle"
                onClick={() => history.push("/home")}
              >
                Menu
              </span>
              <span
                style={{
                  alignItems: "center",
                  justifyItems: "center",
                  marginTop: "%20",
                  fontSize: 16,
                  className: "headertitle",
                  color: "white",
                  fontWeight: "bolder",
                }}
                onClick={() => history.push("/home")}
              >
                ROBOTLAR
              </span>

              {User.User.UserAccesses.map((Robot) => (
                <span
                  style={{ fontSize: 14, marginLeft: "12%" }}
                  onClick={() =>
                    GetPackages_(
                      Robot.ProducedRobots.Robot.Model,
                      Robot.UserAccessId,
                      Robot.ProducedRobotsId
                    )
                  }
                >
                  {Robot.RobotName}
                </span>
              ))}
            </div>
          </Drawer>
        </div>
      )}
      {isDesktopOrLaptop && (
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontSize: 30,
              textAlign: "left",
              marginTop: "1%",
              marginLeft: "48%",
              marginBottom: "1%",
            }}
          >
            {isDesktopOrLaptop && (
              <span onClick={() => history.push("/home")}>
                <img
                  style={{
                    height: 28,
                    width: 80,
                  }}
                  src={global.config.imagepath.images + "roboliza-logo.png"}
                />
              </span>
            )}
          </div>
        </div>
      )}
      {isDesktopOrLaptop && (
        <div style={{ flex: 1 }}>
          <div
            style={{
              fontSize: 15,
              textAlign: "left",
              marginLeft: "40%",
              marginTop: "1%",
              marginBottom: "1%",
              color: "white",
              flexDirection: "row",
              padding: "2%",
              display: "flex",
            }}
          >
            {isDesktopOrLaptop && (
              <Dropdown overlay={menu} arrow>
                <span
                  onClick={(e) => e.preventDefault()}
                  style={{ color: "white", fontWeight: "bolder" }}
                  className="cursor-pointer"
                >
                  Robotlar
                </span>
              </Dropdown>
            )}
            {/* {isDesktopOrLaptop && (
              <span
                onClick={() => history.push("/trailer")}
                style={{ marginLeft: "2%", fontSize: 9, color: "whitesmoke" }}
              >
                Nasıl Kullanırım ?
              </span>
            )} */}
            {isDesktopOrLaptop && (
              <span onClick={() => Exit()} style={{ marginLeft: "2%" }}>
                <AiOutlineLogout
                  className="cursor-pointer"
                  size={25}
                ></AiOutlineLogout>
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
